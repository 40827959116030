<div id="dialog-container" *ngIf="!this.sent">
  <header id="dialog-header">
    <h5>
      <fa-icon [icon]="['fas', 'file-invoice']"></fa-icon>
      {{ 'specific-request-dialog.dialog-title' | translate }} - {{ this.data.title }}
    </h5>
  </header>
  <div id="dialog-intro">
    <ng-container *ngIf="isEtudePEB; else ELECNV3">
      <p>
        {{ 'specific-request-dialog.peb.content-1' | translate }}
      </p>
      <p>
        {{ 'specific-request-dialog.peb.content-2' | translate }}
      </p>
    </ng-container>
    <ng-template #ELECNV3>
      <ng-container *ngIf="isElecNv3; else OVER125A">
        <p>
          {{ 'specific-request-dialog.elec-nv3.content-1' | translate }}
        </p>
        <p>
          {{ 'specific-request-dialog.elec-nv3.content-2' | translate }}
        </p>
      </ng-container>
    </ng-template>
    <ng-template #OVER125A>
      <ng-container *ngIf="isElecNv4; else basic">
        {{ 'specific-request-dialog.elec-nv4.content-1' | translate }}
      </ng-container>
    </ng-template>
    <ng-template #basic>
      <p>
        {{ titleTxt$ | async }} <a href="tel:028802171">02 880 21 71</a>
        {{ 'specific-request-dialog.dialog-content-end' | translate }}
      </p>
      <p>{{ 'specific-request-dialog.dialog-content-conclusion' | translate }}</p>
    </ng-template>
  </div>
  <form id="dialog-content" [formGroup]="devisFormGroup" #myform="ngForm">
    <h6>
      {{ 'section-user.disco.your-info' | translate }}
    </h6>
    <mat-form-field class="devis-firstname" appearance="outline">
      <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
      <input
        matInput
        placeholder="Jane"
        name="firstname"
        autocomplete="given-name"
        formControlName="firstName"
        required
      />
      <mat-error *ngIf="true">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-lastname" appearance="outline">
      <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
      <input
        matInput
        placeholder="Doe"
        name="lastname"
        autocomplete="family-name "
        formControlName="lastName"
        required
      />
      <mat-error *ngIf="devisFormGroup.get('lastName')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-phone" appearance="outline">
      <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
      <input
        matInput
        placeholder="0123 45 67 89"
        type="tel"
        name="phone"
        autocomplete="tel"
        formControlName="phone"
        required
      />
      <mat-error *ngIf="devisFormGroup.get('phone')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-mail" appearance="outline">
      <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
      <input
        matInput
        placeholder="youremail@example.com"
        type="email"
        name="email"
        autocomplete="email"
        formControlName="email"
        required
      />
      <mat-error *ngIf="devisFormGroup.get('email')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="data.needAddress">
      <h6>
        {{
          isHainautOrNamurorNVN
            ? ('MDN-hainaut-namur-nvn.section-propriete.title' | translate)
            : ('section-propriete.title' | translate)
        }}
      </h6>
      <mat-form-field class="devis-street" name="street" appearance="outline">
        <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
        <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
        <mat-error *ngIf="devisFormGroup.get('userStreet')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-num" appearance="outline">
        <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
        <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
        <mat-error *ngIf="devisFormGroup.get('userNum')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-postalcode" appearance="outline">
        <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
        <input matInput placeholder="1234" formControlName="userZip" required />
        <mat-error *ngIf="devisFormGroup.get('userZip')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="devis-city" appearance="outline">
        <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
        <input matInput placeholder="Exemple ville" formControlName="userCity" required />
        <mat-error *ngIf="devisFormGroup.get('userCity')?.hasError('required')">
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="isElecNv3">
      <section>
        <p>{{ 'specific-request-dialog.elec-nv3.more-info' | translate }}</p>
        <ul>
          <li>{{ 'specific-request-dialog.elec-nv3.more-info-item-1' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv3.more-info-item-2' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv3.more-info-item-3' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv3.more-info-item-4' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv3.more-info-item-5' | translate }}</li>
        </ul>
      </section>
    </ng-container>
    <ng-container *ngIf="isElecNv4">
      <div>
        <p>{{ 'specific-request-dialog.elec-nv4.more-info' | translate }}</p>
        <ul>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-1' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-2' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-3' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-4' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-5' | translate }}</li>
          <li>{{ 'specific-request-dialog.elec-nv4.more-info-item-6' | translate }}</li>
        </ul>
      </div>
    </ng-container>

    <mat-form-field appearance="outline" class="text-area">
      <textarea
        matInput
        formControlName="remarks"
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="8"
        placeholder="{{ remarkPlaceholder$ | async }}"
      ></textarea>
    </mat-form-field>
  </form>
  <div id="files-section">
    <p class="intro">{{ fileUploadTitle$ | async }}</p>
    <div id="upload-files-trigger" *ngIf="uploadLinks.length === 0">
      <button class="btn-accent" (click)="fileInput.click()">
        <fa-icon [icon]="['fas', 'upload']"></fa-icon>
        {{ 'specific-request-dialog.choose-file' | translate }}
      </button>
      <span class="input-container">
        <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
      </span>
    </div>
    <div id="upload-list-container" *ngIf="uploadLinks.length > 0">
      <ul id="upload-list">
        <li *ngFor="let file of uploadLinks; let i = index" class="upload-element">
          <div class="info">
            <span class="name ellipsis" matTooltip="{{ 'File ' + fileIndex(i) }}" matTooltipPosition="above"
              >{{ 'File ' + fileIndex(i) }}
            </span>
          </div>
          <a
            [href]="file | safeHtml"
            target="_blank"
            rel="noopener"
            class="action action-download"
            title="{{ 'print-request-dialog.carte.download-file-title' | translate }}"
          >
            <fa-icon [icon]="['fas', 'download']"></fa-icon>
          </a>
          <button
            (click)="deletePhotoLink(i)"
            class="action action-delete"
            title="{{ 'print-request-dialog.carte.delete-file-title' | translate }}"
          >
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </li>
      </ul>
      <div id="upload-other-files-trigger">
        <button (click)="fileInput.click()">
          <fa-icon [icon]="['fas', 'file-image']"></fa-icon>{{ 'specific-request-dialog.add-another-file' | translate }}
        </button>
        <span class="input-container">
          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
        </span>
      </div>
    </div>
  </div>
  <footer id="dialog-footer">
    <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button type="submit" (click)="onSubmit()" class="btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </footer>
</div>
<div id="dialog-confirm" *ngIf="this.sent">
  <picture>
    <fa-icon [icon]="['fas', 'check']"></fa-icon>
  </picture>
  <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
  <p>
    {{ 'specific-request-dialog.success-content' | translate }}
  </p>
  <button (click)="close()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
</div>
