import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { AuthService } from '../core/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { Observable, combineLatest } from 'rxjs';
import * as userSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { TranslateService } from '@ngx-translate/core';
import { SetLanguage } from '../core/store/ui';
import { contactLinks } from '../core/data/header-menu';
import { Router, NavigationStart, RoutesRecognized } from '@angular/router';
import { LoggerService } from '../core/services/logger.service';
import { filter, map, pairwise } from 'rxjs/operators';
import { getHasCommission } from '../core/store/order/order.selectors';
import { UserType } from '../core/models/user';
import { getLanguage } from 'src/app/core/store/ui/ui.selectors';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

type NavLinkId = 'order' | 'customer-space' | 'profile' | 'stats' | 'commission' | 'rates' | 'notifications';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() stepper: MatStepper;
  $isLoggedIn: Observable<boolean>;
  $userEmail: Observable<string>;
  $userID: Observable<string>;
  $tablette: Observable<boolean>;
  $hasCommission: Observable<boolean>;
  $userNotifications: Observable<string[]>;
  userType: UserType;
  $userType: Observable<UserType>;
  open = false;
  currentLang: string;
  languageTypes: 'fr' | 'nl' | 'de' | 'en';
  userID: string;
  isTablette = false;
  isBpLarge = true;
  phoneNumber = '02 88 02 171';
  phoneNumberFormatted = '028802171';

  lang: string;
  languages: {
    id: string;
    name: string;
    flagImgUrl: string;
  }[] = [
    {
      id: 'fr',
      name: 'Français',
      flagImgUrl: 'https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg'
    },
    {
      id: 'nl',
      name: 'Nederlands',
      flagImgUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg'
    },
    {
      id: 'en',
      name: 'English',
      flagImgUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_the_United_Kingdom_%282-3%29.svg'
    },
    {
      id: 'de',
      name: 'Deutsch',
      flagImgUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Germany_%283-2%29.svg'
    }
  ];

  activeNavLinkId?: NavLinkId;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private router: Router,
    private logger: LoggerService,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.$isLoggedIn = this.store.select(userSelector.getIsLoggedIn);
    this.$userEmail = this.store.select(userSelector.getUserEmail);
    this.$userNotifications = this.store.select(userSelector.getUserNotifications);
    this.$userID = this.store.select(userSelector.getUserID);
    this.$tablette = this.store.select(uiSelector.getIsTablette);
    this.$hasCommission = this.store.select(getHasCommission);
    this.$userType = this.store.select(userSelector.getUserType);
    this.$userType.subscribe(ut => (this.userType = ut));
    if (this.router.url) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.phoneNumber = event.url === '/' ? '02 880 21 71' : '02 880 21 71';
          this.phoneNumberFormatted = event.url === '/' ? '028802171' : '028802171';
        }
      });
    }

    this.$userID.subscribe(u => (this.userID = u));

    const $combined = combineLatest([this.$tablette, this.translate.onLangChange]);

    const $result = $combined.pipe(map(results => ({ isTablette: results[0], data: results[1] })));
    $result.subscribe(({ isTablette, data }) => {
      this.isTablette = isTablette;
      this.currentLang = (<any>data).lang;
    });

    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      this.isBpLarge = state.matches;
    });

    // Detect active navigation link
    this.activeNavLinkId = this.mapUrlToNavLinkId(location.pathname);
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.activeNavLinkId = this.mapUrlToNavLinkId(events[1].urlAfterRedirects);
      });
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: { dialog: this.dialog }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.logger.log('The dialog was closed');
    });
  }

  logOut() {
    this.authService.logout();
  }

  switchLanguage(language: 'fr' | 'nl' | 'de' | 'en') {
    this.store.dispatch(new SetLanguage(language));
    if (this.router.url == '/stats') {
      location.reload();
    }
    if (this.router.url == '/customer/rates') {
      location.reload();
    }
  }

  toContact() {
    window.location.href = contactLinks[this.currentLang];
  }
  toProfile() {
    this.router.navigate(['/profile']);
  }
  toStats() {
    this.router.navigate(['/stats']);
  }
  toCommission() {
    this.router.navigate(['/comissions']);
  }

  toOrdering() {
    this.authService.resetOrderForm();
    this.router.navigate(['/']);
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }

  toHomePage() {
    this.store.select(getLanguage).subscribe(ln => (this.lang = ln));
    window.location.href = 'https://www.certinergie.be/' + this.lang + '/';
  }
  toRates() {
    this.router.navigate(['/customer/rates']);
  }
  toLink(link: string) {
    !link.startsWith('http') ? this.router.navigate([link]) : (window.location.href = link);
  }

  toNotifications() {
    this.router.navigate(['/customer/notifications']);
  }

  mapUrlToNavLinkId(url: string): NavLinkId | undefined {
    if (url === '/' || url.startsWith('/?') || url.startsWith('/#')) return 'order';

    if (url.startsWith('/customer/rates')) return 'rates';
    if (url.startsWith('/customer/notifications')) return 'notifications';

    if (url.startsWith('/customer') || url.startsWith('/checkout')) return 'customer-space';
    if (url.startsWith('/profile')) return 'profile';
    if (url.startsWith('/stats')) return 'stats';

    if (url.startsWith('/comissions')) return 'commission';

    return undefined;
  }
}
