import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OrderEffects } from './core/effects/order.effects';
import { reducers, metaReducers } from './app.reducers';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { StoreModule } from '@ngrx/store';
import { PackSelectedComponent } from './snackbars/pack-selected/pack-selected.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { CongratsDialogComponent } from './dialogs/congrats-dialog/congrats-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { OrderSteppersComponent } from './order-steppers/order-steppers.component';
import { Error404Component } from './error404/error404.component';
import { UIEffects } from './core/effects/ui.effects';

import { SpecificRequestDialogComponent } from './dialogs/specific-request-dialog/specific-request-dialog.component';
import { LoaderInterceptorService } from './core/spinner-overlay/loader-interceptor.service';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { CommonModule } from '@angular/common';
import { AgencyWelcomeComponent } from './dialogs/agency-welcome/agency-welcome.component';
import { SummaryConfirmDialogComponent } from './dialogs/summary-confirm-dialog/summary-confirm-dialog.component';
import { DefaultDialogComponent } from './dialogs/default-dialog/default-dialog.component';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { AuditRequestDialogComponent } from './dialogs/audit-request-dialog/audit-request-dialog.component';
import { PrintRequestDialogComponent } from './dialogs/print-request-dialog/print-request-dialog.component';
import { VisitRequestDialogComponent } from './dialogs/visit-request-dialog/visit-request-dialog.component';
import { PosterRequestDialogComponent } from './dialogs/poster-request-dialog/poster-request-dialog.component';
import { SignsRequestDialogComponent } from './dialogs/signs-request-dialog/signs-request-dialog.component';
import { PaperRequestDialogComponent } from './dialogs/paper-request-dialog/paper-request-dialog.component';
import { ServicesChoiceDialogComponent } from './dialogs/services-choice-dialog/services-choice-dialog.component';
import { PrintFullpressRequestDialogComponent } from './dialogs/print-fullpress-request-dialog/print-fullpress-request-dialog.component';
import { NotaryServicesComponent } from './notary-services/notary-services.component';
import { PanelXlRequestDialogComponent } from './dialogs/panel-xl-request-dialog/panel-xl-request-dialog.component';
import { SpecificPrintRequestDialogComponent } from './dialogs/specific-print-request-dialog/specific-print-request-dialog.component';
import { CustomRequestDialogComponent } from './dialogs/custom-request-dialog/custom-request-dialog.component';
import { VenteTypeInfoComponent } from './components/vente-type-info/vente-type-info.component';
import { ServicesDetailsDialogComponent } from './dialogs/services-details-dialog/services-details-dialog.component';
import { SplitInvoiceComponent } from './components/split-invoice/split-invoice.component';
import { StepMoneyResultComponent } from './components/step-money-result/step-money-result.component';
import { UrgenceProComponent } from './components/urgence-pro/urgence-pro.component';
import { DescUrbaCheckboxComponent } from './components/desc-urba-checkbox/desc-urba-checkbox.component';
import { BannerComponent } from './components/banner/banner.component';
import { GlobalMessageComponent } from './dialogs/global-message/global-message.component';
import { PreparePebComponent } from './dialogs/prepare-peb/prepare-peb.component';
import { PrepareElecComponent } from './dialogs/prepare-elec/prepare-elec.component';
import { PrepareCiterneComponent } from './dialogs/prepare-citerne/prepare-citerne.component';
import { PrepareGazComponent } from './dialogs/prepare-gaz/prepare-gaz.component';
import { PrepareImmoComponent } from './dialogs/prepare-immo/prepare-immo.component';
import { DraftDialogComponent } from './dialogs/draft-dialog/draft-dialog.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { FeedbackDialogComponent } from './dialogs/feedback-dialog/feedback-dialog.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthenticationInterceptor } from './core/interceptors/authentication.interceptor';
import { ActPaymentConfirmDialogComponent } from './dialogs/act-payment-confirm-dialog/act-payment-confirm-dialog.component';
import { CommitmentConfirmDialogComponent } from './dialogs/commitment-confirm-dialog/commitment-confirm-dialog.component';
import { InvitePaymentDialogComponent } from './dialogs/invite-payment-dialog/invite-payment-dialog.component';
import { PanelRequestDialogComponent } from './dialogs/panel-request-dialog/panel-request-dialog.component';
import { ProfileComponent } from './client-space/profile/profile/profile.component';
import { ProfileProComponent } from './client-space/profile/profile-pro/profile-pro.component';
import { AddAdressesComponent } from './client-space/profile/add-adresses/add-adresses.component';
import { AddContactComponent } from './client-space/profile/add-contact/add-contact.component';
import { ProfileProMainComponent } from './client-space/profile/profile-pro-main/profile-pro-main.component';
import { ProfileProContactsComponent } from './client-space/profile/profile-pro-contacts/profile-pro-contacts.component';
import { AddContactDialogComponent } from './client-space/profile/add-contact-dialog/add-contact-dialog.component';
import { AddAdressDialogComponent } from './client-space/profile/add-adress-dialog/add-adress-dialog.component';
import { ProfileEditComponent } from './client-space/profile/profile-edit/profile-edit.component';
import { ProfileStatusComponent } from './client-space/profile/profile-status/profile-status.component';
import { ProfileUpgradeStatusComponent } from './client-space/profile/profile-upgrade-status/profile-upgrade-status.component';
import { DeleteContactComponent } from './client-space/profile/delete-contact/delete-contact.component';
import { DeleteAdressComponent } from './client-space/profile/delete-adress/delete-adress.component';
import { ProfileOthersComponent } from './client-space/profile/profile-others/profile-others.component';
import { ProfilePasswordComponent } from './client-space/profile/profile-password/profile-password.component';
import { ProfileComissionsComponent } from './client-space/comissions/profile-comissions/profile-comissions.component';
import { ComissionsInfoComponent } from './client-space/comissions/comissions-info/comissions-info.component';
import { ComissionsDashboardComponent } from './client-space/comissions/comissions-dashboard/comissions-dashboard.component';
import { ComissionsUnpaidComponent } from './client-space/comissions/comissions-unpaid/comissions-unpaid.component';
import { ComissionsCurrentComponent } from './client-space/comissions/comissions-current/comissions-current.component';
import { ListingNotaireComponent } from './client-space/listing-notaire-stats/listing-notaire/listing-notaire.component';
import { AllNotairesComponent } from './client-space/listing-notaire-stats/all-notaires/all-notaires.component';
import { ActifsNotairesComponent } from './client-space/listing-notaire-stats/actifs-notaires/actifs-notaires.component';
import { InactifsNotairesComponent } from './client-space/listing-notaire-stats/inactifs-notaires/inactifs-notaires.component';
import { StatsComponent } from './client-space/listing-notaire-stats/stats/stats.component';
import { EditAdressDialogComponent } from './client-space/profile/edit-adress-dialog/edit-adress-dialog.component';
import { EditContactDialogComponent } from './client-space/profile/edit-contact-dialog/edit-contact-dialog.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { PlainteDialogComponent } from './dialogs/plainte-dialog/plainte-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { StatsGraphComponent } from './client-space/listing-notaire-stats/stats-graph/stats-graph.component';
import { StatsMainComponent } from './client-space/listing-notaire-stats/stats-main/stats-main.component';
import { VisitZyvaRequestDialogComponent } from './dialogs/visit-zyva-request-dialog/visit-zyva-request-dialog.component';
import { BillingInfo } from './step4/billing-info/billing-info.component';
import { IconsModule } from './client-space/icons/icons.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBriefcase,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faCircle,
  faClock,
  faCog,
  faComments,
  faCreditCard,
  faDownload,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFileImage,
  faFileInvoice,
  faFileUpload,
  faHourglass,
  faInfoCircle,
  faPen,
  faPlus,
  faPowerOff,
  faQuestionCircle,
  faReceipt,
  faSearch,
  faShareAlt,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTrash,
  faUndo,
  faUpload,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faShippingFast, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NotaireLandingComponent } from './order-steppers/notaire-landing/notaire-landing-dialog';

registerLocaleData(localeFr);

if (environment.production) {
}

@NgModule({
  declarations: [
    AppComponent,
    OrderSteppersComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    PackSelectedComponent,
    CongratsDialogComponent,
    LoginDialogComponent,
    Error404Component,
    SpecificRequestDialogComponent,
    AgencyWelcomeComponent,
    SummaryConfirmDialogComponent,
    DefaultDialogComponent,
    AuditRequestDialogComponent,
    PrintRequestDialogComponent,
    VisitRequestDialogComponent,
    PosterRequestDialogComponent,
    PanelRequestDialogComponent,
    SignsRequestDialogComponent,
    PaperRequestDialogComponent,
    ServicesChoiceDialogComponent,
    PrintFullpressRequestDialogComponent,
    NotaryServicesComponent,
    PanelXlRequestDialogComponent,
    SpecificPrintRequestDialogComponent,
    CustomRequestDialogComponent,
    VenteTypeInfoComponent,
    ServicesDetailsDialogComponent,
    SplitInvoiceComponent,
    StepMoneyResultComponent,
    UrgenceProComponent,
    DescUrbaCheckboxComponent,
    GlobalMessageComponent,
    PreparePebComponent,
    PrepareElecComponent,
    PrepareGazComponent,
    PrepareImmoComponent,
    PrepareCiterneComponent,
    DraftDialogComponent,
    QrCodeComponent,
    FeedbackDialogComponent,
    ActPaymentConfirmDialogComponent,
    CommitmentConfirmDialogComponent,
    InvitePaymentDialogComponent,
    ProfileComponent,
    ProfileProComponent,
    AddAdressesComponent,
    AddContactComponent,
    ProfileProMainComponent,
    ProfileProContactsComponent,
    AddContactDialogComponent,
    AddAdressDialogComponent,
    ProfileEditComponent,
    ProfileStatusComponent,
    ProfileUpgradeStatusComponent,
    DeleteContactComponent,
    DeleteAdressComponent,
    ProfileOthersComponent,
    ProfilePasswordComponent,
    ProfileComissionsComponent,
    ComissionsInfoComponent,
    ComissionsDashboardComponent,
    ComissionsUnpaidComponent,
    ComissionsCurrentComponent,
    ListingNotaireComponent,
    AllNotairesComponent,
    ActifsNotairesComponent,
    InactifsNotairesComponent,
    StatsComponent,
    EditAdressDialogComponent,
    EditContactDialogComponent,
    PlainteDialogComponent,
    MessageDialogComponent,
    StatsGraphComponent,
    StatsMainComponent,
    VisitZyvaRequestDialogComponent,
    BannerComponent,
    BillingInfo,
    NotaireLandingComponent
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    IconsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    EffectsModule.forRoot([OrderEffects, UIEffects]),
    HttpClientModule,
    FormsModule,
    SharedModule,
    NgxChartsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({}),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    Ng2TelInputModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add only the specific icon to the library
    library.addIcons(
      faQuestionCircle,
      faClipboardCheck,
      faHome,
      faCalculator,
      faShippingFast,
      faPhone,
      faAngleDown,
      faUser,
      faBriefcase,
      faReceipt,
      faCog,
      faTags,
      faPowerOff,
      faCheck,
      faThumbsUp,
      faCheckCircle,
      faInfoCircle,
      faArrowLeft,
      faArrowRight,
      faAngleRight,
      faCalendarAlt,
      faTimes,
      faUpload,
      faExclamation,
      faCircle,
      faSearch,
      faUndo,
      faComments,
      faExclamationTriangle,
      faCreditCard,
      faDownload,
      faThumbsDown,
      faEye,
      faPen,
      faShareAlt,
      faInfoCircle,
      faExternalLinkAlt,
      faHourglass,
      faClock,
      faTrash,
      faFileImage,
      faPlus,
      faFileInvoice,
      faFileUpload,
      faFileInvoice
    );
  }
}
