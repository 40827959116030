import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { getOnline } from '../core/store/ui/ui.selectors';
import { LoggerService } from '../core/services/logger.service';
import { getUserType } from '../core/store/order/order.selectors';
import { UserType } from '../core/models/user';

@Injectable()
export class OrderResolver implements Resolve<any> {
  online: boolean;
  userType: UserType;

  constructor(private dataService: DataService, private store: Store<AppState>, private logger: LoggerService) {
    combineLatest(this.store.select(getOnline), this.store.select(getUserType)).subscribe(([online, userType]) => {
      this.online = online;
      this.userType = userType;
    });
  }

  resolve(): Observable<any> {
    if (this.online) {
      this.logger.log('resolve online');
      return this.dataService.getImmoPacks();
    } else {
      this.logger.log('resolve offline');
      return null;
    }
  }
}
