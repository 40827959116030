import { Component, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-comissions-info',
  templateUrl: './comissions-info.component.html',
  styleUrls: ['./comissions-info.component.scss']
})
export class ComissionsInfoComponent {
  @ViewChild('alert', { static: true }) alert: ElementRef;
  @Input() comissions;

  closeAlert() {
    const alert: HTMLDivElement = this.alert.nativeElement;
    alert.parentElement.removeChild(alert);
  }
}
