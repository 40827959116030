import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/services/auth.service';
import { getUserID } from 'src/app/core/store/order/order.selectors';

@Component({
  selector: 'app-congrats-dialog',
  templateUrl: './congrats-dialog.component.html',
  styleUrls: ['./congrats-dialog.component.scss']
})
export class CongratsDialogComponent implements OnInit {
  onlinePayment: boolean;
  orderid: string;
  dialog: MatDialog;
  agenda = false;
  isLoggedIn: boolean;
  createOrderResponseUserId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      onlinePayment: boolean;
      orderid: string;
      dialog: MatDialog;
      displayAgenda: boolean;
      createOrderResponseUserId: string;
    },
    private router: Router,
    private store: Store,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.onlinePayment = this.data.onlinePayment;
    this.dialog = this.data.dialog;
    this.orderid = this.data.orderid;
    this.agenda = this.data.displayAgenda;
    this.createOrderResponseUserId = this.data.createOrderResponseUserId;
    this.store.select(getUserID).subscribe(uid => (this.isLoggedIn = !!uid));
  }

  async toAgenda() {
    if (!this.isLoggedIn) await this.authService.loginWithUserIDAsync(this.createOrderResponseUserId);
    this.dialog.closeAll();
    this.router.navigate(['/checkout/agenda', this.orderid]);
  }

  async toCheckout() {
    if (!this.isLoggedIn) await this.authService.loginWithUserIDAsync(this.createOrderResponseUserId);
    this.dialog.closeAll();
    this.router.navigate(['/checkout', this.orderid]);
  }

  async toClientArea() {
    if (!this.isLoggedIn) await this.authService.loginWithUserIDAsync(this.createOrderResponseUserId);
    this.dialog.closeAll();
    this.router.navigate(['/customer']);
  }

  closeModal() {
    this.dialog.closeAll();
    this.router.navigate(['']);
  }
}
