import * as estates from './estates';
import { UserType, UserRole } from '../models/user';
import { Reasons } from '../enums/reason.enum';
import { VENTES } from '../enums/vente.enum';

import * as PRODUCTS from '../data/products';

export const descriptifPacks = [52, 54];
export const displayedPacks = [];

export const ONLINE_REDUC = 10;

export const orderMeansType = 1;

export const specialPrices = [];

export const services = [
  {
    id: 1,
    name: 'Certificat PEB',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-peb',
    reasons: [Reasons.VENTE, Reasons.LOCATION, Reasons.CONTROLE]
  },
  {
    id: 4,
    name: 'Contrôle électrique',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-elec',
    reasons: [Reasons.VENTE, Reasons.CONTROLE]
  },
  {
    id: 9,
    name: 'Contrôle citerne',
    regions: [0, 1],
    biens: [estates.HOUSE],
    fileName: 'service-citerne',
    reasons: [Reasons.VENTE, Reasons.CONTROLE]
  },
  {
    id: 15,
    name: 'Ouverture de compteur Gaz + CERGA',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-gaz',
    reasons: [Reasons.CONTROLE]
  },
  {
    id: 28,
    name: 'PEB chauffage type 1',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-peb-chauffage',
    reasons: [Reasons.CONTROLE]
  },
  {
    id: 49,
    name: 'PEB chauffage type 2',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-peb-chauffage',
    reasons: [Reasons.CONTROLE]
  },
  {
    id: 100,
    name: 'Contrôle Gaz périodique',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-gaz',
    reasons: [Reasons.CONTROLE]
  },
  /* {
    id: 50,
    name: 'Entretien chaudière',
    regions: [1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-chaudiere-entretien',
    reasons: [Reasons.CONTROLE]
  }, */
  {
    id: PRODUCTS.AUDIT,
    name: 'Audit',
    regions: [0],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-audit',
    reasons: [Reasons.RENOVATION]
  },
  {
    id: PRODUCTS.RESP_PEB,
    name: 'Responsabilité PEB / Conseil PEB',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.APPART, estates.BUILDING, estates.INDUSTRY, estates.STORE, estates.OFFICE],
    fileName: 'service-descriptif',
    reasons: [Reasons.RENOVATION]
  },
  {
    id: PRODUCTS.ELEC_PHOTOVOLTA,
    name: 'Photovoltaïque - Borne recharge - Batterie',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-elec-battery',
    reasons: [Reasons.CONTROLE]
  },
  /*   {
    id: PRODUCTS.AMIANTE,
    name: 'Amiante',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-descriptif',
    reasons: [Reasons.CONTROLE]
  }, */
  {
    id: PRODUCTS.ELEC_NONDOM_MAX125A,
    name: 'Elec non résidentiel (<125A)',
    regions: [0, 1, 2],
    biens: [estates.OFFICE, estates.STORE, estates.INDUSTRY, estates.GARAGE],
    fileName: 'service-elec',
    reasons: []
  },
  {
    id: PRODUCTS.ELEC_NONDOM_OVER125A,
    name: 'Elec non résidentiel (>125A)',
    regions: [0, 1, 2],
    biens: [estates.OFFICE, estates.STORE, estates.INDUSTRY, estates.GARAGE],
    fileName: 'service-elec',
    reasons: []
  },
  {
    id: PRODUCTS.FireLvl2Check,
    name: 'Contrôle de conformité du système de détection incendie',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireLvl1Periodical,
    name: 'Périodique système de détection incendie',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireCheckGasDetectors,
    name: 'Contrôle détecteurs de gaz',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireCheckHoseReelAndHydrant,
    name: 'Contrôle dévidoirs et hydrants',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireCheckEmergencyLighting,
    name: 'Contrôle éclairage de secours',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireCheckSmokeEvacuation,
    name: 'Contrôle des évacuations de fumée',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  },
  {
    id: PRODUCTS.FireLvl1Or2TestFireDetection,
    name: 'Test de détection incendie',
    regions: [],
    biens: [],
    fileName: 'service-fire',
    reasons: []
  }
];

export const servicesSupp = [
  {
    id: 6,
    name: 'Contrôle citerne aérienne',
    regions: [0, 1],
    biens: [estates.HOUSE],
    fileName: 'service-citerne',
    reasons: [Reasons.CONTROLE, Reasons.LOCATION]
  }
];

export const immoServices = [
  {
    id: 14,
    name: 'Plan 2D',
    regions: [0, 1, 2],
    biens: [
      estates.HOUSE,
      estates.FLAT,
      estates.BUILDING,
      estates.OFFICE,
      estates.STORE,
      estates.INDUSTRY,
      estates.GARAGE
    ],
    fileName: 'service-plan',
    userTypes: [],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 26,
    name: 'Plan 3D',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-3d',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 21,
    name: 'Visite 360°',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.OFFICE, estates.STORE, estates.INDUSTRY, estates.GARAGE],
    fileName: 'service-360',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 18,
    name: 'Photo [HDR]',
    regions: [0, 1, 2],
    biens: [
      estates.HOUSE,
      estates.FLAT,
      estates.BUILDING,
      estates.TERRAIN,
      estates.OFFICE,
      estates.STORE,
      estates.INDUSTRY,
      estates.GARAGE
    ],
    fileName: 'service-photo',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 19,
    name: 'Photos aériennes',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.LAND],
    fileName: 'service-drone',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 20,
    name: 'Vidéo descriptive',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-video',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 111,
    name: 'Video Teasing',
    regions: [],
    biens: [],
    fileName: 'service-video',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: []
  },
  {
    id: 27,
    name: 'Descriptif',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-descriptif',
    free: true,
    userTypes: [UserType.Notaire],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 101,
    name: 'Vidéo avec vue drônes',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.LAND],
    fileName: 'service-drone',
    userTypes: [UserType.Notaire, UserType.Agence],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: -2,
    name: 'Web vitrine du bien',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-plaquette',
    free: true,
    userTypes: [UserType.Notaire],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: PRODUCTS.NETTMAISON,
    name: 'Nettoyage maison',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-maison',
    userTypes: [UserType.Notaire],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: PRODUCTS.NETTJARDIN,
    name: 'Nettoyage Jardin',
    regions: [0, 1],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-jardin',
    userTypes: [UserType.Notaire],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: PRODUCTS.ZYVA,
    name: 'Visite by ZYVA',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'service-visite',
    userTypes: [UserType.Notaire],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  }
];

export const panneauxServices = [
  /* {
    id: 29,
    name: 'Panneau Biddit',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.TERRAIN],
    fileName: 'panneau-basic',
    userTypes: [],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 30,
    name: 'Panneau pack',
    regions: [0, 1, 2],
    biens: [],
    fileName: 'panneau-basic',
    userTypes: [],
    reasons: [],
    venteTypes: []
  },
  {
    id: 102,
    name: 'Commande panneaux',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.TERRAIN],
    fileName: 'panneau-placement-alone',
    userTypes: [],
    reasons: [],
    venteTypes: []
  }, */
  {
    id: -42,
    name: 'Panneau XL',
    regions: [0, 1, 2],
    biens: [],
    fileName: 'panneau-xl',
    userTypes: [],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 32,
    name: 'Pose panneau collé',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.TERRAIN],
    fileName: 'panneau-placement-alone',
    userTypes: [],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  },
  {
    id: 33,
    name: 'Pose panneau support',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING, estates.TERRAIN],
    fileName: 'panneau-placement-alone',
    userTypes: [],
    reasons: [],
    venteTypes: []
  },
  {
    id: 34,
    name: 'SMS',
    regions: [0, 1, 2],
    biens: [estates.HOUSE],
    fileName: 'panneau-sms',
    free: true,
    userTypes: [],
    reasons: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  }
];

export const immoPacks = [
  {
    id: 51,
    name: 'Contrôle combi peb elec',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4],
    fileName: 'controle-combi.png',
    reduc: 10,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Avocat
    ],
    userRoles: [],
    show: true
  },
  {
    id: 55,
    name: 'Contrôle combi gaz élec',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [4, 15],
    fileName: 'controle-combi.png',
    reduc: 35,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Avocat
    ],
    userRoles: [],
    show: true
  },
  {
    id: 52,
    name: 'Média combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [14, 18, 21],
    fileName: 'media-combi.png',
    reduc: 181,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Avocat
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  },
  {
    id: 53,
    name: 'Immo light combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4, 18],
    fileName: 'immo-light-combi.png',
    reduc: 40,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Avocat
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  },
  {
    id: 54,
    name: 'Immo combi',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    services: [1, 4, 14, 18, 21],
    fileName: 'immo-combi.png',
    reduc: 309,
    userTypes: [
      UserType.Particulier,
      UserType.Agence,
      UserType.Certificateur,
      UserType.Notaire,
      UserType.Installateur,
      UserType.Avocat
    ],
    userRoles: [UserRole.ImmoAccess],
    show: true
  }
];

export const publicationServices = [
  {
    id: -41,
    name: 'Publication à la carte',
    regions: [0, 1, 2],
    biens: [estates.HOUSE, estates.FLAT, estates.BUILDING],
    fileName: 'lavenir',
    userTypes: [],
    venteTypes: [VENTES.VENTE_PUB, VENTES.GRE_A_GRE, VENTES.BIDDIT]
  }
];

export const mdnServices = [];

export const estateStyles = [
  {
    id: -1,
    name: 'Sélectionnez un type de bien',
    type: '',
    fileName: 'type-estate'
  },
  {
    id: 0,
    name: 'Maison',
    type: 'house',
    fileName: 'type-maison'
  },
  {
    id: 1,
    name: 'Appartement',
    type: 'flat',
    fileName: 'type-appart'
  },
  {
    id: 2,
    name: 'Immeuble d’appartements',
    type: 'building',
    fileName: 'type-immeuble'
  },
  {
    id: 4,
    name: 'Non résidentiel',
    type: 'non-res',
    fileName: 'type-terrain'
  }
];
