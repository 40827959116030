import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from '../models/loaderState';
import { SpinnerOverlayService } from './spinner-overlay.service';

@Component({
  selector: 'app-loader',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;
  constructor(private loaderService: SpinnerOverlayService) {}
  ngOnInit() {
    this.subscription = this.loaderService?.loaderState?.subscribe((state: LoaderState) => {
      this.show = state.show;
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }
}
