import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getIsLoggedIn } from '../../store/order/order.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (route.queryParams.u) return true; // Bypass auth guard if login via userid

    return this.store.select(getIsLoggedIn).pipe(
      map(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigateByUrl(
            `/?login=true&login-redirect-url=${encodeURIComponent(location.pathname + location.search)}`
          );
          return false;
        }
        return true;
      })
    );
  }
}
