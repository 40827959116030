import { BreakpointObserver, type BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-comissions-current',
  templateUrl: './comissions-current.component.html',
  styleUrls: ['./comissions-current.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ComissionsCurrentComponent implements OnInit {
  @Input() activeFilter;
  @Input() comissions;
  isBpLarge = true;
  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      this.isBpLarge = state.matches;
    });
  }
}
