import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { imgagesFolder } from 'src/app/core/services/globals';

@Component({
  selector: 'app-big-select',
  templateUrl: './big-select.component.html',
  styleUrls: ['./big-select.component.scss']
})
export class BigSelectComponent implements OnInit {
  @Input() selected: number;
  @Input() placeholder: string;
  @Input() items: BigSelectInput[];
  @Input() isMobile: boolean;
  @Output() valueChange = new EventEmitter();
  @Input() translationString: string;
  @Input() elementClass: string;
  @Input() disabled: boolean;

  noPlaceHolderItems: BigSelectInput[];

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    // test items is not empty
    if (this.items !== undefined && this.items.length !== 0) {
      this.noPlaceHolderItems = this.items.slice(1);
    }
  }

  selectedClass(selectedId) {
    const cls = this.selected === selectedId ? 'selected' : '';
    return cls;
  }

  imgUrl(fileName, selectedId) {
    let url = imgagesFolder;
    url += fileName;
    url += this.selected === selectedId ? '-selected.svg' : '.svg';
    return url;
  }

  valueChanged(id, disabled) {
    if (this.disabled || disabled) return;
    this.valueChange.emit(id);
  }

  get selectedItem() {
    return this.items.find(p => p.id === this.selected);
  }
}

export interface BigSelectInput {
  id: number;
  name: string;
  fileName?: string;
  disabled?: boolean;
}
