import { BreakpointObserver, type BreakpointState } from '@angular/cdk/layout';
import { Component, ViewChild, ElementRef, OnInit, Input, Renderer2, ViewEncapsulation } from '@angular/core';

const CSS_CLASSES = {
  tabActive: 'tab-active',
  tabGreen: 'green',
  filterActive: 'filter-active',
  filterNotActive: 'filter-not-active',
};

@Component({
  selector: 'app-comissions-dashboard',
  templateUrl: './comissions-dashboard.component.html',
  styleUrls: ['./comissions-dashboard.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ComissionsDashboardComponent implements OnInit {
  @ViewChild('unPaid', { static: true }) unPaid: ElementRef;
  @ViewChild('comission', { static: true }) comission: ElementRef;
  @ViewChild('segmented', { static: true }) segmented: ElementRef;
  @ViewChild('table', { static: true }) table: ElementRef;

  @Input() comissions: any;

  activeTab: string = 'unPaid';
  activeFilter: string = 'table';
  isBpLarge = true;

  constructor(private renderer: Renderer2, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver.observe(['(min-width: 992px)']).subscribe((state: BreakpointState) => {
      this.isBpLarge = state.matches;
    });
  }

  setTab(tab: string): void {
    if (tab !== this.activeTab) {
      const previousTab = this[this.activeTab].nativeElement;
      this.renderer.removeClass(previousTab, CSS_CLASSES.tabActive);
      this.renderer.removeClass(previousTab, CSS_CLASSES.tabGreen);

      this.activeTab = tab;

      const newTab = this[tab].nativeElement;
      this.renderer.addClass(newTab, CSS_CLASSES.tabActive);
      if (tab !== 'unPaid') {
        this.renderer.addClass(newTab, CSS_CLASSES.tabGreen);
      }
    }
  }

  setFilter(filter: string): void {
    if (filter !== this.activeFilter) {
      const previousFilter = this[this.activeFilter]?.nativeElement;
      const newFilter = this[filter]?.nativeElement;
  
      if (previousFilter) {
        this.renderer.removeClass(previousFilter, CSS_CLASSES.filterActive);
        this.renderer.addClass(previousFilter, CSS_CLASSES.filterNotActive);
      }
  
      this.activeFilter = filter;
  
      if (newFilter) {
        this.renderer.addClass(newFilter, CSS_CLASSES.filterActive);
        this.renderer.removeClass(newFilter, CSS_CLASSES.filterNotActive);
      }
    }
  }

  print(): void {
    const css = '@page { size: landscape; }';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    style.type = 'text/css';
    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);

    this.setTab('comission');
    setTimeout(() => {
      const email = (document.querySelector('.btn-connected') as HTMLElement)?.textContent || '';
      const titleDiv = document.getElementById('commisionTitle');
      if (titleDiv) {
        titleDiv.textContent = `${titleDiv.textContent} (${email})`;
      }
      window.print();
    }, 2000);
  }
}
