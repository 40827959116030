<ng-container *ngIf="isEmbedded">
  <router-outlet></router-outlet>

  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="!isEmbedded">
  <div class="app-container">
    <app-header [stepper]="null">
      <router-outlet></router-outlet>
    </app-header>

    <app-footer></app-footer>
  </div>

  <app-loader></app-loader>
</ng-container>
