import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompleteNotaireLandingDialog } from 'src/app/core/store/order';
import { AppState } from 'src/app/interfaces';

@Component({
  selector: 'app-notaire-landing',
  templateUrl: './notaire-landing.component.html',
  styleUrls: ['./notaire-landing.component.scss']
})
export class NotaireLandingComponent implements OnInit {
  displayedServices: { url: string; translationKey: string }[] = [
    { url: '../../../assets/images/service-peb.svg', translationKey: 'homepage.section-services.peb' },
    { url: '../../../assets/images/service-elec.svg', translationKey: 'homepage.section-services.elec' },
    { url: '../../../assets/images/service-citerne.svg', translationKey: 'homepage.section-services.citerne-short' },
    { url: '../../../assets/images/service-gaz.svg', translationKey: 'homepage.section-services.gaz' }
  ];

  redirectionLinks: { url: string; translationKey; comingSoon?: boolean }[] = [
    {
      url: 'https://maisondunotariatdenamur.be/services',
      translationKey: 'notaire-landing-dialog.content.links.namur'
    },
    {
      url: 'https://mnbw.be/services',
      translationKey: 'notaire-landing-dialog.content.links.brabant-wallon'
    },
    {
      url: 'https://maisondunotariatduhainaut.be/services',
      translationKey: 'notaire-landing-dialog.content.links.hainaut'
    },
    {
      url: 'https://notalux.com/services',
      translationKey: 'notaire-landing-dialog.content.links.luxembourg'
    },
    {
      url: 'https://cnal.be/services',
      translationKey: 'notaire-landing-dialog.content.links.liege'
    },
    {
      url: 'https://nvn.be/other-services?o=66k99t4435d4f',
      translationKey: 'notaire-landing-dialog.content.links.bruxelles'
    }
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {}

  continueToCertinergieOrder() {
    this.store.dispatch(new CompleteNotaireLandingDialog(true));
  }
}
