import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdressType } from '../../../core/enums/adress-type.enum';
import { DataService } from '../../../core/services/data.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-edit-adress-dialog',
  templateUrl: './edit-adress-dialog.component.html',
  styleUrls: ['./edit-adress-dialog.component.scss']
})
export class EditAdressDialogComponent implements OnInit {
  newAdress: FormGroup;
  displayError = false;
  errorContent = 'Veuillez bien remplir le formulaire';
  errorCreation = 'Un problème est survenu , réessayer';
  adressType = AdressType;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.newAdress = this.fb.group({
      Country: [this.data.adress.Country],
      City: [this.data.adress.City],
      PostalCode: [this.data.adress.PostalCode],
      Street: [this.data.adress.Street],
      Num: [this.data.adress.Number],
      Name: [this.data.adress.Name, [Validators.required]],
      NotReceiveInvoice: [!this.data.adress.NotReceiveInvoice, [Validators.required]],
      NotReceiveReport: [!this.data.adress.NotReceiveReport, [Validators.required]],
      Type: [this.data.adress.Type],
      // Latitude: [''],
      // Longitude: [''],
      AppartementNumber: [this.data.adress.ApartmentNumber],
      BoxNumber: [this.data.adress.BoxNumber],
    });
    this.newAdress.controls['City'].disable();
    this.newAdress.controls['PostalCode'].disable();
    this.newAdress.controls['Street'].disable();
    this.newAdress.controls['Num'].disable();
    this.newAdress.controls['BoxNumber'].disable();
  }
  editAdress() {
      this.newAdress.controls['NotReceiveInvoice'].setValue(!this.newAdress.controls['NotReceiveInvoice'].value);
      this.newAdress.controls['NotReceiveReport'].setValue(!this.newAdress.controls['NotReceiveReport'].value);
      const toPatch = [
        { 'op': 'replace', 'path': '/Name', 'value': this.newAdress.get('Name').value  },
        { 'op': 'replace', 'path': '/Type', 'value': this.newAdress.get('Type').value },
        { 'op': 'replace', 'path': '/NotReceiveInvoice', 'value': this.newAdress.get('NotReceiveInvoice').value  },
        { 'op': 'replace', 'path': '/NotReceiveReport', 'value': this.newAdress.get('NotReceiveReport').value },
      ];
      this.dataService.editAdress(toPatch, this.data.id).subscribe(data => {
        if ((data.status = 204)) {
          this.dataService.getProfile();
          this.data.dialog.closeAll();

        } else {
          this.data.dialog.closeAll();
        }
      });
  }
}
