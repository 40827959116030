<div class="editInfo">
  <h4 class="modalTitle break">{{ 'profile.editCompanyInfo' | translate }}</h4>
  <span id="close" (click)="this.data.dialog.closeAll()">
    <app-close-icon></app-close-icon>
  </span>
  <form *ngIf="this.data.profile" class="content" [formGroup]="info">
    <div class="row">
      <div class="col-md-12 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyName' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 ptop formField">
          <input matInput required placeholder="Dénomination commerciale" formControlName="CommercialName"
            type="text" />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12"></div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyEmail' | translate }}</span>
        <mat-form-field appearance="outline" class=" col-12 formField">
          <input matInput required readonly placeholder="joxx@xxxx.xx" formControlName="Email" type="email" />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-6">{{ 'profile.form' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <mat-label>{{ 'profile.form' | translate }}</mat-label>
          <mat-select formControlName="LegalForm">
            <mat-option *ngFor="let form of legalForm" [value]="form.value">
              {{ form.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyWebsite' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <input matInput placeholder="www.xxxxxx.xx" formControlName="Website" type="text" />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyTVA' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <input readonly matInput placeholder="BE 0751 4546" formControlName="VatNumber" type="text" />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyPhone' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <input matInput required placeholder="02/xxx.xx.xx" mask="00/000.00.00" formControlName="Tel" type="text" />
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <span class="modalSubtitle col-12">{{ 'profile.companyGsm' | translate }}</span>
        <mat-form-field appearance="outline" class="col-12 formField">
          <input matInput placeholder="04xx/xx.xx.xx" mask="0000000/00.00.00||0000/00.00.00" formControlName="Gsm"
            type="text" />
        </mat-form-field>
      </div>
    </div>

    <!-- <mat-error id="errorMsg" *ngIf="displayError && this.info.status != 'VALID'">{{ this.errorContent }}</mat-error> -->
    <p id="infoEdit">Merci de nous contacter au 02 880 21 71 pour modifier votre adresse email et numéro de tva.</p>
    <button class="btn btn-primary" type="submit" (click)="editInfo()" [disabled]="info.invalid">
      {{ 'profile.editCompanyInfo' | translate }}
    </button>
  </form>
</div>