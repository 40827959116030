<div id="dialog-container" *ngIf="!this.sent">
  <header id="dialog-header">
    <h5>
      <fa-icon [icon]="['fas', 'file-invoice']"></fa-icon>
      {{ 'panel-xl-request-dialog.title' | translate }}
    </h5>
  </header>
  <div id="dialog-intro">
    <p>
      {{ 'panel-xl-request-dialog.intro' | translate }}
    </p>
  </div>
  <div id="dialog-content">
    <form id="panel-xl-form" (ngSubmit)="onSubmit()" [formGroup]="devisFormGroup">
      <mat-form-field appearance="outline" class="text-area">
        <textarea
          matInput
          formControlName="instructions"
          cdkTextareaAutosize
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="5"
          placeholder="{{ 'panel-xl-request-dialog.placeholder' | translate }}"
        ></textarea>
      </mat-form-field>
    </form>
  </div>
  <div id="dialog-footer">
    <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button type="submit" class="btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </div>
</div>
<div id="dialog-confirm" *ngIf="this.sent">
  <picture>
    <fa-icon [icon]="['fas', 'check']"></fa-icon>
  </picture>
  <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
  <p>
    {{ 'specific-request-dialog.success-content' | translate }}
  </p>
  <button class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
</div>
