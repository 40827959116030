<mat-accordion *ngIf="this.activeFilter == 'table' && isBpLarge">
  <mat-expansion-panel class="wrap" [expanded]="true">
    <mat-expansion-panel-header class="accordion-unPaid">
      <mat-panel-title class="white"> {{ 'profile.totalAmountUnpaid' | translate }}. </mat-panel-title>
      <mat-panel-description class="white" id="toPay">
        TVAC {{ this.comissions.TotalUnpaid }},00 €
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="content">
      <div class="row spaceRow">
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.client' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.adress' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.facture' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.infoSup' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.notPaid' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="subTitle">{{ 'comission.see' | translate }}</span>
        </div>
      </div>
      <div *ngFor="let comm of comissions.UnpaidOrders" class="row spaceRow">
        <div class="col-2 align-center">
          <span class="response">{{ comm.Customer }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="response">{{ comm.Address }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="response"
            >{{ 'comission.facture' | translate }} <br />
            <span class="grey">{{ comm.InvoiceNumber }}</span></span
          >
        </div>
        <div class="col-2 align-center">
          <span *ngIf="comm.CommissionAllowed" class="response green"
            >{{ 'comission.valid' | translate }}
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path
                  d="M9.0006 7.44996C8.5769 8.17365 7.97428 8.77627 7.2506 9.19996C6.52239 9.62561 5.69408 9.84993 4.8506 9.84993C4.00712 9.84993 3.17881 9.62561 2.4506 9.19996C1.72691 8.77627 1.1243 8.17365 0.700599 7.44996C0.265867 6.72559 0.0408377 5.89472 0.0505975 5.04996C0.0408377 4.20521 0.265867 3.37434 0.700599 2.64996C1.1243 1.92628 1.72691 1.32366 2.4506 0.899964C3.17881 0.474321 4.00712 0.25 4.8506 0.25C5.69408 0.25 6.52239 0.474321 7.2506 0.899964C7.97428 1.32366 8.5769 1.92628 9.0006 2.64996C9.43533 3.37434 9.66036 4.20521 9.6506 5.04996C9.66036 5.89472 9.43533 6.72559 9.0006 7.44996Z"
                  fill="white"
                />
                <path
                  d="M9.0006 7.44996C8.5769 8.17365 7.97428 8.77627 7.2506 9.19996C6.52239 9.62561 5.69408 9.84993 4.8506 9.84993C4.00712 9.84993 3.17881 9.62561 2.4506 9.19996C1.72691 8.77627 1.1243 8.17365 0.700599 7.44996C0.265867 6.72559 0.0408377 5.89472 0.0505975 5.04996C0.0408377 4.20521 0.265867 3.37434 0.700599 2.64996C1.1243 1.92628 1.72691 1.32366 2.4506 0.899964C3.17881 0.474321 4.00712 0.25 4.8506 0.25C5.69408 0.25 6.52239 0.474321 7.2506 0.899964C7.97428 1.32366 8.5769 1.92628 9.0006 2.64996C9.43533 3.37434 9.66036 4.20521 9.6506 5.04996C9.66036 5.89472 9.43533 6.72559 9.0006 7.44996ZM7.8506 3.99996C7.88165 3.97668 7.90685 3.94648 7.92421 3.91177C7.94156 3.87705 7.9506 3.83877 7.9506 3.79996C7.9506 3.76115 7.94156 3.72287 7.92421 3.68816C7.90685 3.65345 7.88165 3.62325 7.8506 3.59996L7.4506 3.14996C7.42731 3.11891 7.39712 3.09371 7.3624 3.07636C7.32769 3.059 7.28941 3.04996 7.2506 3.04996C7.21179 3.04996 7.17351 3.059 7.1388 3.07636C7.10408 3.09371 7.07388 3.11891 7.0506 3.14996L4.1506 6.04996L2.8006 4.69996C2.77731 4.66891 2.74712 4.64371 2.7124 4.62636C2.67769 4.609 2.63941 4.59996 2.6006 4.59996C2.56179 4.59996 2.52351 4.609 2.4888 4.62636C2.45408 4.64371 2.42388 4.66891 2.4006 4.69996L2.0006 5.14996C1.96955 5.17325 1.94435 5.20345 1.92699 5.23816C1.90963 5.27287 1.9006 5.31115 1.9006 5.34996C1.9006 5.38877 1.90963 5.42705 1.92699 5.46177C1.94435 5.49648 1.96955 5.52668 2.0006 5.54996L4.0006 7.54996C4.02389 7.58101 4.05408 7.60621 4.08879 7.62357C4.12351 7.64093 4.16179 7.64996 4.2006 7.64996C4.23941 7.64996 4.27769 7.64093 4.3124 7.62357C4.34712 7.60621 4.37731 7.58101 4.4006 7.54996L7.8506 3.99996Z"
                  fill="#8EC640"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="10" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg> </span
          ><span *ngIf="!comm.CommissionAllowed" class="response red">{{ 'comission.notvalid' | translate }}</span>
        </div>
        <div class="col-2 align-center">
          <span class="response red">€ {{ comm.UnpaidAmount }}</span>
        </div>
        <div class="col-2 align-center">
          <a href="{{ comm.InvoiceLink }}" target="_blank" class="response green"
            ><svg
              class="click"
              width="27"
              height="29"
              viewBox="0 0 27 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="27" height="29" rx="5" fill="#E96136" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.875 10.125C7.875 8.74429 8.99429 7.625 10.375 7.625H15.5895C16.2525 7.625 16.8884 7.88839 17.3572 8.35723L18.3928 9.39277C18.8616 9.86161 19.125 10.4975 19.125 11.1605V18.875C19.125 20.2557 18.0057 21.375 16.625 21.375H10.375C8.99429 21.375 7.875 20.2557 7.875 18.875V10.125ZM17.875 12V18.875C17.875 19.5654 17.3154 20.125 16.625 20.125H10.375C9.68464 20.125 9.125 19.5654 9.125 18.875V10.125C9.125 9.43464 9.68464 8.875 10.375 8.875H14.75V10.125C14.75 11.1605 15.5895 12 16.625 12H17.875ZM17.8057 10.75C17.7443 10.5735 17.6435 10.4113 17.5089 10.2767L16.4733 9.24112C16.3387 9.10647 16.1765 9.00571 16 8.94434V10.125C16 10.4702 16.2798 10.75 16.625 10.75H17.8057Z"
                fill="white"
              />
              <path
                d="M13.7392 18.2026C13.813 18.1721 13.882 18.1269 13.9419 18.0669L15.8169 16.1919C16.061 15.9479 16.061 15.5521 15.8169 15.3081C15.5729 15.064 15.1771 15.064 14.9331 15.3081L14.125 16.1161V13.25C14.125 12.9048 13.8452 12.625 13.5 12.625C13.1548 12.625 12.875 12.9048 12.875 13.25V16.1161L12.0669 15.3081C11.8229 15.064 11.4271 15.064 11.1831 15.3081C10.939 15.5521 10.939 15.9479 11.1831 16.1919L13.0581 18.0669C13.2422 18.2511 13.5127 18.2963 13.7392 18.2026Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion *ngIf="this.activeFilter == 'segmented' || !isBpLarge">
  <mat-expansion-panel class="wrap" [expanded]="true">
    <mat-expansion-panel-header class="accordion-unPaid">
      <mat-panel-title class="white"> {{ 'profile.totalAmountUnpaid' | translate }}. </mat-panel-title>
      <mat-panel-description class="white" id="toPay">
        TVAC {{ this.comissions.TotalUnpaid }},00 €
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="cards-grid">
      <div *ngFor="let commi of comissions.UnpaidOrders">
        <div class="segmented">
          <div class="row spaceRowSeg">
            <svg
              class="col-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 13C8.13401 13 5 16.134 5 20V22C5 22.5523 4.55228 23 4 23C3.44772 23 3 22.5523 3 22V20C3 15.0294 7.02944 11 12 11C16.9706 11 21 15.0294 21 20V22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22V20C19 16.134 15.866 13 12 13Z"
                fill="#C4C4C4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 10.3137 8.68629 13 12 13Z"
                fill="#C4C4C4"
              /></svg
            ><span class="col-10 bold"> {{ commi.Customer }} </span>
          </div>
          <hr />
          <div class="row spaceRowSeg">
            <svg
              class="col-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.6415 16.2445C18.0423 14.3001 19 12.1631 19 10C19 6.13401 15.866 3 12 3C8.13401 3 5 6.13401 5 10C5 12.1631 5.95769 14.3001 7.35846 16.2445C8.74868 18.1742 10.4581 19.7503 11.6769 20.7485C11.8768 20.9122 12.1232 20.9122 12.3231 20.7485C13.5419 19.7503 15.2513 18.1742 16.6415 16.2445ZM13.5904 22.2957C16.1746 20.1791 21 15.4917 21 10C21 5.02944 16.9706 1 12 1C7.02944 1 3 5.02944 3 10C3 15.4917 7.82537 20.1791 10.4096 22.2957C11.3466 23.0631 12.6534 23.0631 13.5904 22.2957Z"
                fill="#C4C4C4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8ZM8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10Z"
                fill="#C4C4C4"
              /></svg
            ><span class="col-10"> {{ commi.Address }} </span>
          </div>
          <hr />
          <div class="row spaceRowSeg">
            <svg
              class="col-2"
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 8.56738C9.89302 8.56738 9.46143 8.8191 9.46143 9.19002V14.2372C9.46143 14.5551 9.89307 14.8731 10.5 14.8731C11.08 14.8731 11.5521 14.5551 11.5521 14.2372V9.19002C11.5521 8.8191 11.08 8.56738 10.5 8.56738Z"
                fill="#C4C4C4"
              />
              <path
                d="M10.5001 5.4541C9.87962 5.4541 9.39404 5.89126 9.39404 6.39465C9.39404 6.89804 9.87962 7.34846 10.5001 7.34846C11.1071 7.34846 11.5926 6.89804 11.5926 6.39465C11.5926 5.8913 11.107 5.4541 10.5001 5.4541Z"
                fill="#C4C4C4"
              />
              <path
                d="M10.5 0C4.69627 0 0 4.61006 0 10.3065C0 14.958 3.11817 18.8203 7.32218 20.132L9.71007 23.5889C9.88771 23.846 10.1836 24 10.5 24C10.8164 24 11.1123 23.846 11.2899 23.5889L13.6778 20.132C17.8837 18.8197 21 14.956 21 10.3065C21 4.60645 16.3 0 10.5 0ZM12.8217 18.4265C12.6043 18.4862 12.4155 18.6196 12.2888 18.8031L10.5 21.3926L8.71123 18.8031C8.58447 18.6197 8.3957 18.4862 8.17831 18.4266C4.59981 17.4438 1.90909 14.2116 1.90909 10.3065C1.90909 5.65734 5.76297 1.875 10.5 1.875C15.237 1.875 19.0909 5.65734 19.0909 10.3065C19.0909 14.2155 16.3962 17.4449 12.8217 18.4265Z"
                fill="#C4C4C4"
              /></svg
            ><span class="col-10"> {{ 'comission.infoSup' | translate }}<br /> </span>
            <div class="col-2"></div>

            <span *ngIf="commi.CommissionAllowed" class="col-10 response green"
              ><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path
                    d="M9.00011 7.44996C8.57641 8.17365 7.9738 8.77627 7.25011 9.19996C6.5219 9.62561 5.69359 9.84993 4.85011 9.84993C4.00663 9.84993 3.17832 9.62561 2.45011 9.19996C1.72643 8.77627 1.12381 8.17365 0.700111 7.44996C0.265378 6.72559 0.0403494 5.89472 0.0501092 5.04996C0.0403494 4.20521 0.265378 3.37434 0.700111 2.64996C1.12381 1.92628 1.72643 1.32366 2.45011 0.899964C3.17832 0.474321 4.00663 0.25 4.85011 0.25C5.69359 0.25 6.5219 0.474321 7.25011 0.899964C7.9738 1.32366 8.57641 1.92628 9.00011 2.64996C9.43484 3.37434 9.65987 4.20521 9.65011 5.04996C9.65987 5.89472 9.43484 6.72559 9.00011 7.44996Z"
                    fill="white"
                  />
                  <path
                    d="M9.00011 7.44996C8.57641 8.17365 7.9738 8.77627 7.25011 9.19996C6.5219 9.62561 5.69359 9.84993 4.85011 9.84993C4.00663 9.84993 3.17832 9.62561 2.45011 9.19996C1.72643 8.77627 1.12381 8.17365 0.700111 7.44996C0.265378 6.72559 0.0403494 5.89472 0.0501092 5.04996C0.0403494 4.20521 0.265378 3.37434 0.700111 2.64996C1.12381 1.92628 1.72643 1.32366 2.45011 0.899964C3.17832 0.474321 4.00663 0.25 4.85011 0.25C5.69359 0.25 6.5219 0.474321 7.25011 0.899964C7.9738 1.32366 8.57641 1.92628 9.00011 2.64996C9.43484 3.37434 9.65987 4.20521 9.65011 5.04996C9.65987 5.89472 9.43484 6.72559 9.00011 7.44996ZM7.85011 3.99996C7.88116 3.97668 7.90636 3.94648 7.92372 3.91177C7.94107 3.87705 7.95011 3.83877 7.95011 3.79996C7.95011 3.76115 7.94107 3.72287 7.92372 3.68816C7.90636 3.65345 7.88116 3.62325 7.85011 3.59996L7.45011 3.14996C7.42682 3.11891 7.39663 3.09371 7.36191 3.07636C7.3272 3.059 7.28892 3.04996 7.25011 3.04996C7.2113 3.04996 7.17302 3.059 7.13831 3.07636C7.10359 3.09371 7.0734 3.11891 7.05011 3.14996L4.15011 6.04996L2.80011 4.69996C2.77682 4.66891 2.74663 4.64371 2.71191 4.62636C2.6772 4.609 2.63892 4.59996 2.60011 4.59996C2.5613 4.59996 2.52302 4.609 2.48831 4.62636C2.45359 4.64371 2.4234 4.66891 2.40011 4.69996L2.00011 5.14996C1.96906 5.17325 1.94386 5.20345 1.9265 5.23816C1.90915 5.27287 1.90011 5.31115 1.90011 5.34996C1.90011 5.38877 1.90915 5.42705 1.9265 5.46177C1.94386 5.49648 1.96906 5.52668 2.00011 5.54996L4.00011 7.54996C4.0234 7.58101 4.05359 7.60621 4.08831 7.62357C4.12302 7.64093 4.1613 7.64996 4.20011 7.64996C4.23892 7.64996 4.2772 7.64093 4.31191 7.62357C4.34663 7.60621 4.37682 7.58101 4.40011 7.54996L7.85011 3.99996Z"
                    fill="#8EC640"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="10" height="10" fill="white" />
                  </clipPath>
                </defs></svg
              >{{ 'comission.valid' | translate }}
            </span>
            <span *ngIf="!commi.CommissionAllowed" class="col-10 response red">{{
              'comission.notvalid' | translate
            }}</span>
          </div>
          <hr />
          <div class="row spaceRowSeg">
            <a class="col-3" href="{{ commi.InvoiceLink }}" target="_blank">
              <svg
                class="click"
                width="48"
                height="51"
                viewBox="0 0 48 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="51" rx="5" fill="#E96136" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 18C15 15.7909 16.7909 14 19 14H27.3431C28.404 14 29.4214 14.4214 30.1716 15.1716L31.8284 16.8284C32.5786 17.5786 33 18.596 33 19.6569V32C33 34.2091 31.2091 36 29 36H19C16.7909 36 15 34.2091 15 32V18ZM31 21V32C31 33.1046 30.1046 34 29 34H19C17.8954 34 17 33.1046 17 32V18C17 16.8954 17.8954 16 19 16H26V18C26 19.6569 27.3431 21 29 21H31ZM30.8891 19C30.7909 18.7176 30.6296 18.4581 30.4142 18.2426L28.7574 16.5858C28.5419 16.3704 28.2824 16.2091 28 16.1109V18C28 18.5523 28.4477 19 29 19H30.8891Z"
                  fill="white"
                />
                <path
                  d="M24.3828 30.9241C24.5007 30.8753 24.6112 30.803 24.7071 30.7071L27.7071 27.7071C28.0976 27.3166 28.0976 26.6834 27.7071 26.2929C27.3166 25.9024 26.6834 25.9024 26.2929 26.2929L25 27.5858V23C25 22.4477 24.5523 22 24 22C23.4477 22 23 22.4477 23 23V27.5858L21.7071 26.2929C21.3166 25.9024 20.6834 25.9024 20.2929 26.2929C19.9024 26.6834 19.9024 27.3166 20.2929 27.7071L23.2929 30.7071C23.5875 31.0018 24.0203 31.0741 24.3828 30.9241Z"
                  fill="white"
                />
              </svg>
            </a>
            <span class="col-9"
              >{{ 'comission.facture' | translate }} <span class="grey"> {{ commi.InvoiceNumber }} </span> <br />
              <div class="redd">{{ commi.UnpaidAmount }}€</div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
