<div id="dialog-container" [class]="'details-dialog details-dialog-' + this.data.serviceId">
  <button (click)="closeDialog()" class="btn-link" id="close-button">
    <fa-icon [icon]="['fas', 'times']"></fa-icon>
  </button>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PLAN">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.plan2d' | translate }}
        <small>{{ 'services-details-dialog.plan2d-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img
        src="../../../assets/images/plans-details/plans-details-visual-1.jpg"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
      <img
        src="../../../assets/images/plans-details/plans-details-visual-2.jpg"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
    </div>
    <header>
      <h5>
        {{ 'services-details-dialog.plan3d' | translate }}
        <small>{{ 'services-details-dialog.plan3d-end' | translate }}</small>
      </h5>
    </header>
    <div id="more-content">
      <img src="../../../assets/images/plans-details/plans-details-visual-3.png" alt="" class="grid-element" />
      <img
        src="../../../assets/images/plans-details/plans-details-visual-4.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
      <img
        src="../../../assets/images/plans-details/plans-details-visual-5.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PHOTO">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.plan3d' | translate }}
        <small>{{ 'services-details-dialog.plan3d-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img
        src="../../../assets/images/photo-details/photo-details-visual-01.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-02.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-03.png"
        alt=""
        class="grid-element grid-element__small-3 grid-element__medium-3"
      />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-04.png"
        alt=""
        class="grid-element grid-element__small-3 grid-element__medium-3"
      />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-05.png"
        alt=""
        class="grid-element grid-element__small-3 grid-element__medium-3"
      />
    </div>
    <header>
      <h5>
        {{ 'services-details-dialog.drone' | translate }}
        <small>{{ 'services-details-dialog.plan3d-end' | translate }}</small>
      </h5>
    </header>
    <div id="more-content">
      <img src="../../../assets/images/photo-details/photo-details-visual-06.png" alt="" class="grid-element" />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-07.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
      <img
        src="../../../assets/images/photo-details/photo-details-visual-08.png"
        alt=""
        class="grid-element grid-element__small-2 grid-element__medium-2"
      />
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.VISITE360">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.360' | translate }}
        <small>{{ 'services-details-dialog.360-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <iframe _ngcontent-c8="" allowfullscreen="" src="https://envisite.net/vtour/fr/pjynsx"></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.VIDEO">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.video' | translate }}
        <small>{{ 'services-details-dialog.video-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <iframe
        src="https://www.youtube.com/embed/cYf6_Y2eYtU"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <header>
      <h5>
        {{ 'services-details-dialog.video-drone' | translate }}
        <small>{{ 'services-details-dialog.video-drone-end' | translate }}</small>
        <footer class="btn-option">
          <button (click)="addServiceNoClose(PRODUCTS.PHOTODRONE)" class="btn">
            {{ 'services-details-dialog.option' | translate }}
          </button>
        </footer>
      </h5>
    </header>
    <div id="more-content">
      <iframe
        src="https://www.youtube.com/embed/e7_1fqBiGdg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.LIVRET">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.booklet' | translate }}
        <small>{{ 'services-details-dialog.booklet-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <iframe
        style="border: 1px solid #777;"
        src="https://indd.adobe.com/embed/291132a5-8d95-4e68-921c-42c6efff425b?
            startpage=1&allowFullscreen=true"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.AFFICHETTE">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.poster' | translate }}
        <small>{{ 'services-details-dialog.poster-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img src="../../../assets/images/services-details/affiche.jpg" alt="" />
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PLAQUETTE">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.webvitrine' | translate }}
        <small>{{ 'services-details-dialog.poster-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <a href="https://tuttimmo.be/?O=eefb7eff-df1d-4946-8fb1-186158a88b76&L=0" target="_blank">
        <img src="../../../assets/images/services-details/plaquette.jpg" alt="" />
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PANELBIDDIT && this.data.venteType === 2; else notBiddit">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.biddit-pannels' | translate }}
        <small>{{ 'services-details-dialog.biddit-pannels-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img src="../../../assets/images/services-details/biddit.jpg" alt="" />
    </div>
  </ng-container>
  <ng-template #notBiddit>
    <ng-container *ngIf="this.data.serviceId === PRODUCTS.PANELBIDDIT">
      <header id="dialog-header">
        <h5>
          {{ 'services-details-dialog.pack-pannels' | translate }}
          <small>{{ 'services-details-dialog.pack-pannels-end' | translate }}</small>
        </h5>
      </header>
      <div id="dialog-content">
        <img src="../../../assets/images/services-details/biddit.jpg" alt="" />
      </div>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PANEL">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.placement-pannels' | translate }}
        <small>{{ 'services-details-dialog.placement-pannels-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img src="../../../assets/images/services-details/posepanneau.jpg" alt="" />
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.PANEL_XL">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.xxl-pannels' | translate }}
        <small>{{ 'services-details-dialog.xxl-pannels-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img src="../../../assets/images/services-details/placement-XL.jpg" alt="" />
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.DESCRIPTIF_URBA">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.summary' | translate }}
        <small>{{ 'services-details-dialog.summary-end' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <iframe
        style="border: 1px solid #777;"
        src="https://indd.adobe.com/embed/3dbbeb30-7a87-4c1e-a6bf-5df6a166d31d?
                  startpage=1&allowFullscreen=true"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="this.data.serviceId === PRODUCTS.SMS">
    <header id="dialog-header">
      <h5>
        {{ 'services-details-dialog.sms-code' | translate }}
        <small>{{ 'services-details-dialog.sms-code' | translate }}</small>
      </h5>
    </header>
    <div id="dialog-content">
      <img src="../../../assets/images/services-details/code-sms.jpg" alt="" />
    </div>
  </ng-container>
  <footer *ngIf="displayAddService" id="dialog-footer">
    <button (click)="addService(this.data.serviceId)" class="btn">
      {{ 'services-details-dialog.add-to-order' | translate }}
    </button>
  </footer>
</div>
