<ng-container *ngIf="projectID === 'namur' || projectID === 'liege'">
  <header id="dialog-header">
    <h5>
      <fa-icon [icon]="['fas', 'street-view']"></fa-icon>
      {{ 'visit-request-dialog.choice.valoris.title' | translate }}
    </h5>
  </header>
  <ng-container *ngIf="reason === 1; else notGreGre">
    <div id="dialog-intro">
      <p>{{ 'visit-request-dialog.choice.valoris.intro-1' | translate }}</p>
      <div id="summary" [innerHTML]="'visit-request-dialog.choice.valoris.info-1' | translate"></div>
    </div>
  </ng-container>
  <ng-template #notGreGre>
    <div id="dialog-intro">
      <p>{{ 'visit-request-dialog.choice.valoris.intro-2' | translate }}</p>
      <div id="summary" [innerHTML]="'visit-request-dialog.choice.valoris.info-2' | translate"></div>
    </div>
  </ng-template>
  <div id="dialog-content">
    <div id="options">
      <mat-checkbox [(ngModel)]="adValoris.options[0].active">
        <div class="explanation">
          <p>
            <strong>{{ 'visit-request-dialog.choice.valoris.option.ouverture.title' | translate }}</strong>
          </p>
          <p>{{ 'visit-request-dialog.choice.valoris.option.ouverture.info' | translate }}</p>
        </div>
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="adValoris.options[1].active">
        <div class="explanation">
          <p>
            <strong>{{ 'visit-request-dialog.choice.valoris.option.valeur.title' | translate }}</strong>
          </p>
          <p>{{ 'visit-request-dialog.choice.valoris.option.valeur.info' | translate }}</p>
        </div>
      </mat-checkbox>
    </div>
    <div id="comment-section">
      <p class="intro">
        Des instructions complémentaires à nous transmettre ?
      </p>
      <form id="dialog-content" [formGroup]="infoFormGroup">
        <mat-form-field appearance="outline">
          <textarea
            matInput
            formControlName="instructions"
            cdkTextareaAutosize
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"
            placeholder="Instructions complémentaires au sujet des visites"
          ></textarea>
        </mat-form-field>
      </form>
    </div>
    <div id="price">
      {{ 'visit-request-dialog.choice.valoris.price' | translate }}
    </div>
  </div>
  <footer id="dialog-footer">
    <button (click)="cancel()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button (click)="AddVisit()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
  </footer>
</ng-container>
<ng-container *ngIf="projectID === 'hainaut'">
  <header id="dialog-header">
    <h5>
      <fa-icon [icon]="['fas', 'street-view']"></fa-icon>
      {{ 'visit-request-dialog.choice.gil-1.title' | translate }}
    </h5>
  </header>
  <ng-container *ngIf="reason === 1; else notGreGre">
    <div id="dialog-intro">
      <p>{{ 'visit-request-dialog.choice.gil-1.intro' | translate }}</p>
      <div id="summary" [innerHTML]="'visit-request-dialog.choice.gil-1.info' | translate"></div>
    </div>
    <div id="dialog-content">
      <div id="price">
        {{ 'visit-request-dialog.choice.gil-1.price' | translate }}
      </div>
    </div>
  </ng-container>
  <ng-template #notGreGre>
    <div id="dialog-content">
      <p>{{ 'visit-request-dialog.choice.gil-2.intro' | translate }}</p>
      <mat-checkbox (click)="forfait1Click()" [(ngModel)]="gilVPnBid.forfaits[0].active">
        {{ 'visit-request-dialog.choice.gil-2.forfait.4Sem.title' | translate }}
      </mat-checkbox>
      <mat-checkbox (click)="forfait2Click()" [(ngModel)]="gilVPnBid.forfaits[1].active">
        {{ 'visit-request-dialog.choice.gil-2.forfait.6Sem.title' | translate }}
      </mat-checkbox>
      <p>Options :</p>
      <mat-checkbox (click)="option1Click()" [(ngModel)]="gilVPnBid.options[0].active">
        {{ 'visit-request-dialog.choice.gil-2.option.2Vis.title' | translate }}
      </mat-checkbox>
      <mat-checkbox (click)="option2Click()" [(ngModel)]="gilVPnBid.options[1].active">
        {{ 'visit-request-dialog.choice.gil-2.option.illim.title' | translate }}
      </mat-checkbox>
      <div id="price">
        {{ 'visit-request-dialog.choice.gil-2.price' | translate: { totalPrice: totalPrice } }}
      </div>
    </div>
  </ng-template>
  <footer id="dialog-footer">
    <button (click)="cancelGil()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button (click)="AddVisitGil()" class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
  </footer>
</ng-container>
