<div class="upgrade-status">
  <h4 class="modalTitle break">{{'profile.deleteContact' | translate}}</h4>
  <span id="close" (click)="this.data.dialog.closeAll()">
    <app-close-icon></app-close-icon>
  </span>
  <p class="text">{{'profile.deleteContactConfirm' | translate}}</p>
  <div class="row">
    <div class="col-md-6 col-12 fle">
      <button (click)="this.annuler()" class="f-r btn btn-secondary">{{'profile.discard' | translate}}</button>
    </div>
    <div class="col-md-6 col-12 fle">
      <button (click)="this.deleteContact(this.data.id)" class="f-l btn btn-danger">{{'profile.deleteContact' |
        translate}}</button>
    </div>
  </div>
</div>