<div *ngIf="profile" class="contacts white">
  <div class="row spaceRow">
    <span class="col-lg-6 col-md-6 col-12 profileTitle">{{ 'profile.contactList' | translate }}</span>
    <div class="col-lg-6 col-md-6 col-12 cent">
      <button class="addBtn btn-primary" (click)="addContact()">{{ 'profile.addContact' | translate }}</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-12">
      <div class="mainContact">
        <div class="row">
          <div class="col-12">
            <p id="mainContactIta">{{ 'profile.mainContact' | translate }}</p>
            <div *ngIf="profile.MainUser.Title">
              {{ profile.MainUser.Title }}
            </div>
            <div class="editDel">
              <span id="mainContactRep">
                {{profile.MainUser.FirstName ? profile.MainUser.FirstName +' '+ profile.MainUser.Name :
                profile.MainUser.Name}}
              </span>
              <!-- <svg (click)="editContact(true , profile.MainUser , profile.UserId)" id="mrr" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.24687 2.93313L1.34125 10.8388L1 14.0806C0.943125 14.5072 1.31281 14.8769 1.73937 14.82L4.98125 14.4787L12.8869 6.57313L9.24687 2.93313ZM15.1334 2.39281L13.4272 0.686563C12.9153 0.14625 12.0337 0.14625 11.4934 0.686563L9.90094 2.27906L13.5409 5.91906L15.1334 4.32656C15.6737 3.78625 15.6737 2.90469 15.1334 2.39281Z"
                  fill="#989898"
                />
              </svg> -->
            </div>
          </div>
        </div>
        <div class="infos">
          <div class="col-12 p0">
            <span style="font-style: italic;" class="mainContactRep">{{ profile.MainUser.Email }}</span>
          </div>
          <div class="col-12 p0">
            <span style="font-style: italic;" class="mainContactRep">{{ profile.MainUser.PhoneNumber ?
              profile.MainUser.PhoneNumber : profile.MainUser.TelNumber }}</span>
          </div>
        </div>
        <div class="ptop">
          <div class="row space">
            <span class="col-9">{{ 'profile.contactNotifOrder' | translate }}</span>
            <app-notif-order-icon
              [hide]="false"
              [svgClass]="'col-3'"
              [width]="'20'"
              [height]="'20'"
            ></app-notif-order-icon>
          </div>
          <div class="row">
            <span class="col-9">{{ 'profile.contactNotifAll' | translate }}</span>
            <app-notif-all-icon
            [hide]="false"
            [svgClass]="'col-3'"
            [width]="'20'"
            [height]="'20'"
            ></app-notif-all-icon>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let contact of profile.Contacts" class="col-lg-4 col-md-6 col-12">
      <div class="mainContact ">
        <div class="row">
          <div *ngIf="contact.Title" class="col-12">
            {{'profile.title'+contact.Title | translate }}
          </div>
          <div class="col-12">
            <div class="editDel">
              <span id="mainContactRep">
                {{contact.FirstName ? contact.FirstName +' '+ contact.Name : contact.Name}}
              </span>
              <div>
                <app-edit-icon (editClick)="editContact(false, contact, contact.ContactId)"></app-edit-icon>
                <app-delete-icon (deleteClick)="popDelete(contact.ContactId)"></app-delete-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="infos">
          <div class="col-12 p0">
            <span style="font-style: italic;" class="mainContactRep">{{ contact.Email }}</span>
          </div>
          <div class="col-12 p0">
            <span style="font-style: italic;" class="mainContactRep">{{ contact.PhoneNumber ? contact.PhoneNumber:
              contact.TelNumber }}</span>
          </div>
          <div class="col-12 p0 ">
            <span *ngIf="contact.Function!==null" class="mainContactRep">
              {{ 'profile.' + functionType[contact.Function] | translate }}
            </span>
          </div>
        </div>

        <div class="ptop">
          <div class="row space">
            <span class="col-9">{{ 'profile.contactNotifOrder' | translate }}</span>
            <app-notif-order-icon 
              [hide]="contact?.UnsubscribedOrderInformations"
              [svgClass]="'col-3'"
              [width]="'20'"
              [height]="'20'"
            ></app-notif-order-icon>
          </div>

          <div class="row">
            <span class="col-9">{{ 'profile.contactNotifAll' | translate }}</span>
            <app-notif-all-icon 
              [hide]="contact?.UnsubscribedAllInformations"
              [svgClass]="'col-3'"
              [width]="'20'"
              [height]="'20'"
            ></app-notif-all-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>