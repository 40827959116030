import { Component, OnInit, Input } from '@angular/core';
import { PricingPlans } from '../../../core/enums/pricing-plan.enum';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUpgradeStatusComponent } from '../profile-upgrade-status/profile-upgrade-status.component';
import { Router, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-profile-status',
  templateUrl: './profile-status.component.html',
  styleUrls: ['./profile-status.component.scss']
})
export class ProfileStatusComponent implements OnInit {
  @Input() profile;
  PricingPlans = PricingPlans;
  statusPlans;
  constructor(public dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
    this.statusPlans = Object.values(this.PricingPlans);
  }
  upgrade() {
    this.dialog.open(ProfileUpgradeStatusComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog }
    });
  }
  toComission() {
    this.router.navigate(['/comissions']);
  }
}
