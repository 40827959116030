import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: environment?.googleMapsApiKey,
      version: 'weekly',
      libraries: ['places'], // Add other libraries if needed
    });
  }

  loadGoogleMaps(): Promise<typeof google> {
    return this.loader.load();
  }
}