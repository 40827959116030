import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-icon',
  template: `
    <svg (click)="onEditClick()" id="mr" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24687 2.93313L1.34125 10.8388L1 14.0806C0.943125 14.5072 1.31281 14.8769 1.73937 14.82L4.98125 14.4787L12.8869 6.57313L9.24687 2.93313ZM15.1334 2.39281L13.4272 0.686563C12.9153 0.14625 12.0337 0.14625 11.4934 0.686563L9.90094 2.27906L13.5409 5.91906L15.1334 4.32656C15.6737 3.78625 15.6737 2.90469 15.1334 2.39281Z"
        fill="#989898"
      />
    </svg>
  `,
  styles: [],
})
export class EditIconComponent {
  @Output() editClick = new EventEmitter<void>();

  onEditClick(): void {
    this.editClick.emit();
  }
}