<h5 *ngIf="!succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-calculate-here' | translate }}
  <small *ngIf="step1SubTitle">
    {{
      ($hasImmoAccess | async) ? ('step1.header-immo' | translate) : ('step1.header-no-immo' + projectID | translate)
    }}
  </small>
</h5>

<h5 *ngIf="succintStep1 && !step1TitleImmo" class="step-introduction">
  {{ 'step1.header-summary' + projectID | translate }} {{ 'section-situation.region.' + currentRegionId | translate }}
</h5>

<h5 *ngIf="step1TitleImmo" class="step-introduction">
  {{ 'step1.header-mdn' | translate }}
</h5>

<div [class]="'step-content ' + (products?.length > 0 ? 'step-money-visible' : '')">
  <ng-container *ngIf="$isNotary | async; else agencyOrOther">
    <!-- <section *ngIf="!($isLoggedIn | async)" class="section-situation" id="card-1">
        <h6>{{ 'section-client.title' | translate }}</h6>
        <app-big-select
          class="client-type"
          [selected]="$clientType | async"
          [items]="clientTypes"
          [isMobile]="$isMobile | async"
          (valueChange)="setClientType($event)"
          [translationString]="'section-client.client-type.'"
          [elementClass]="'clientType'"
        ></app-big-select>
    </section> -->

    <section class="section-situation">
      <h6>{{ 'section-situation.title' | translate }}</h6>
      <div class="section-situation-container">
        <label>{{ 'section-situation.question' | translate }}</label>
        <mat-form-field class="user-firstname" appearance="outline">
          <input
            matInput
            name="zip"
            placeholder="Ex: 1000"
            autocomplete="zip"
            [formControl]="situationControl"
            required
          />
          <mat-error>
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="hasDefinedRegionAndZipcode" class="situation-result">
          <img [src]="imgUrlRegion($selectedRegion | async)" />
          <p>{{ 'section-situation.region.' + ($selectedRegion | async) | translate }}</p>
        </div>
      </div>

      <ng-container *ngIf="($selectedRegion | async) === 2 && !isAvailableZipFlanders; else sectionTypeTemp">
        <br />
        <div class="not-available">{{ 'section-situation.not-available' | translate }}</div>
      </ng-container>
    </section>
    <section
      *ngIf="$hasDefinedRegionAndZipcode | async"
      class="section-situation immo"
      id="card-1"
      style="position: relative;"
    >
      <div style="position: absolute; top: -125px; left: 0" #sectioImmoOrCtrl></div>
      <h6>{{ 'section-immo-or-ctrl.title' | translate }}</h6>
      <app-big-select
        class="client-type"
        [selected]="$immoOrCtrlSelected | async"
        [items]="immoOrCtrlOptions"
        [isMobile]="$isMobile | async"
        (valueChange)="setImmoOrCtrl($event, sectioImmoOrCtrl)"
        [translationString]="
          isHainautOrNamurorNVN ? 'section-immo-or-ctrl.options-mdn.' : 'section-immo-or-ctrl.options.'
        "
        [elementClass]="'clientType'"
      ></app-big-select>
    </section>
    <section class="section-type" id="card-2" *ngIf="($immoOrCtrlSelected | async) != -1" style="position: relative;">
      <div style="position: absolute; top: -125px; left: 0" #sectionType></div>
      <h6>
        <span>{{ 'section-type.title' | translate }}</span>
        <app-tooltip
          [title]="'section-type.title-tooltip.title' | translate"
          [content]="'section-type.title-tooltip.content' | translate"
          [emphasis]="'section-type.title-tooltip.emphasis' | translate"
        ></app-tooltip>
      </h6>
      <app-big-select
        class="estate-selector"
        [selected]="$selectedEstateStyle | async"
        [items]="estateStyles"
        [isMobile]="$isMobile | async"
        (valueChange)="setEstateStyle($event)"
        [translationString]="'section-type.style.'"
        [elementClass]="'estate'"
      ></app-big-select>
      <ul class="sub-questions">
        <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.house.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="houseControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let houseOption of houseOptions"
                (click)="setEstateType(houseOption.typeId, sectionType)"
                [value]="houseOption.typeId"
              >
                {{ 'estateTpes.' + houseOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.flat.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="appartControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let appartOption of appartOptions"
                (click)="setEstateType(appartOption.typeId, sectionType)"
                [value]="appartOption.typeId"
              >
                {{ 'estateTpes.' + appartOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li
          *ngIf="($selectedEstateStyle | async) === 2"
          [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'"
        >
          <label>{{ 'section-type.sub.building.label' | translate }}</label>
          <app-tooltip
            style="margin-right: 0.75rem;"
            [title]="'section-type.sub.building.title-tooltip.title' | translate"
            [content]="'section-type.sub.building.title-tooltip.content' | translate"
            [emphasis]="'section-type.sub.building.title-tooltip.emphasis' | translate"
          ></app-tooltip>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="buildingControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let immeubleOption of immeubleOptions"
                (click)="setNbOfAppartments(immeubleOption.id, sectionType)"
                [value]="immeubleOption.id"
              >
                {{ immeubleOption.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{
              'inputs.select-required' | translate
            }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 4" [class]="nonResControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.non-res' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="nonResControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let nonResOption of nonResOptions"
                (click)="setEstateType(nonResOption.typeId, sectionType)"
                [value]="nonResOption.typeId"
              >
                {{ 'estateTpes.' + nonResOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="nonResControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
      </ul>
    </section>
    <section
      *ngIf="
        (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
        (!succintStep1 && (($selectedEstateType | async) !== -1 || ($selectedEstateStyle | async) === 3)) &&
        (($immoOrCtrlSelected | async) === 1 || showMoreServices)
      "
      class="section-vente-type"
    >
      <h6>{{ 'section-vente-type.title' | translate }}</h6>
      <app-big-select
        class="vente-type"
        [selected]="$venteType | async"
        [items]="venteTypes"
        [isMobile]="$isMobile | async"
        (valueChange)="setVenteType($event)"
        [translationString]="'section-vente-type.vente-type.'"
        [elementClass]="'venteType'"
      ></app-big-select>
    </section>
    <section
      class="section-services"
      id="card-3"
      *ngIf="
        (($selectedEstateType | async) !== -1 || ($selectedEstateStyle | async) === 3) &&
        (($venteType | async) !== -1 || ($immoOrCtrlSelected | async) === 0)
      "
    >
      <h6>{{ 'section-services.title' | translate }}</h6>
      <!-- All pack here -->
      <div *ngIf="$immoPacksWithPrices | async as immoPacks" id="notary-pack-container">
        <div
          *ngFor="let immoPack of immoPacks"
          class="services-pack-element"
          [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
        >
          <header class="pack-header subtitle">
            {{ immoPack.name }}
            <span class="pack-pricing-big"
              >{{ (immoPack.totalWithReduc?.toFixed(2) | htva)?.toFixed(2) }}<small>€ HTVA</small></span
            >
            <!-- <span class="pack-pricing-small">{{ immoPack.totalWithReduc?.toFixed(2) }}€ TVAC</span> -->
            <span *ngIf="immoPack.proPackDisplay"
              ><small>{{ 'section-services.split-invoice' | translate }}</small></span
            >
          </header>
          <div class="pack-details open">
            <span *ngIf="immoPack.servicesNotary && immoPack.servicesNotary?.length > 0" class="pack-details-title">{{
              'section-services.service-certinergie' | translate
            }}</span>
            <ul>
              <li *ngFor="let serviceID of immoPack.services" class="pack-details-element">
                <img [src]="serviceImgUrl(null, serviceID)" />
                <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
                <button
                  *ngIf="displayDetailsBtn(serviceID)"
                  (click)="openDetailsDialog(serviceID)"
                  class="details-dialog-trigger btn-link"
                >
                  <div
                    [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                    [removeSVGAttributes]="['class']"
                    [setSVGAttributes]="svgPrimaryColor"
                  ></div>
                </button>
              </li>
              <ng-container *ngIf="packHasDescriptif(immoPack.services)">
                <li class="pack-details-element">
                  <img src="../../assets/images/service-plaquette.svg" />
                  <span>{{ 'section-money.reductions.-2' | translate }}</span
                  >²
                  <button
                    *ngIf="displayDetailsBtn(PRODUCTS.PLAQUETTE)"
                    (click)="openDetailsDialog(PRODUCTS.PLAQUETTE)"
                    class="details-dialog-trigger btn-link"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </li>
                <!-- <li class="pack-details-element">
                  <img src="../../assets/images/service-descriptif.svg" />
                  <span>{{ 'section-money.reductions.27' | translate }}</span>
                  <button
                    *ngIf="displayDetailsBtn(PRODUCTS.LIVRET)"
                    (click)="openDetailsDialog(PRODUCTS.LIVRET)"
                    class="details-dialog-trigger btn-link"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </li> -->
                <li class="pack-details-element">
                  <img src="../../assets/images/service-descriptif.svg" />
                  <span>{{ 'section-money.reductions.-8' | translate }}</span>
                  <button
                    *ngIf="displayDetailsBtn(PRODUCTS.AFFICHETTE)"
                    (click)="openDetailsDialog(PRODUCTS.AFFICHETTE)"
                    class="details-dialog-trigger btn-link"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>
          <div *ngIf="immoPack.servicesNotary && immoPack.servicesNotary?.length > 0" class="pack-details open">
            <span class="pack-details-title">Services maison du notariat</span>
            <ul>
              <li *ngFor="let serviceID of immoPack.servicesNotary" class="pack-details-element">
                <img src="../../assets/images/mdn-namur/mdn-icon.svg" />
                <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
              </li>
            </ul>
          </div>
          <button class="btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
            {{ 'section-services.choose-this-pack' | translate }}
          </button>
          <button class="btn-accent btn-selected" (click)="setImmoPack(immoPack.description)">
            {{ 'section-services.pack-selected' | translate }}
          </button>
        </div>
      </div>
      <!-- All carte here -->
      <div *ngIf="!($showPacks | async)" class="services-carte-container">
        <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
          <!-- Section Proximag -->
          <mat-expansion-panel
            *ngIf="
              (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
              ($selectedEstateStyle | async) !== 2 &&
              ($filteredPublicationsServices | async)?.length > 0 &&
              ($immoOrCtrlSelected | async) > 0
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.pub' | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredPublicationsServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <div class="tooltip-more">
                  <button
                    *ngIf="displayDetailsBtn(service.id)"
                    (click)="openDetailsDialog(service.id, $event)"
                    class="details-dialog-trigger btn-link tooltip-more-trigger"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Section controle -->
          <mat-expansion-panel
            *ngIf="($filteredServices | async)?.length > 0"
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.control' | translate }}
                  <small>({{ howManyControlSelected }}/{{ ($filteredServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredServices | async"
                [class]="'option ' + serviceClass(service.id)"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">
                  {{ 'section-services.services.' + service.id | translate }}
                </p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <button
                  *ngIf="displayDetailsBtn(service.id)"
                  (click)="openDetailsDialog(service.id, $event)"
                  class="details-dialog-trigger btn-link"
                >
                  <div
                    [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                    [removeSVGAttributes]="['class']"
                    [setSVGAttributes]="svgPrimaryColor"
                  ></div>
                </button>
              </div>
            </div>
            <ul
              *ngIf="hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1)"
              class="sub-questions"
            >
              <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
                <label>{{ 'section-services.peb-partiel.title' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="pebPartiel">
                    <mat-option (click)="setPebPartiel(false)" [value]="false">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option (click)="setPebPartiel(true)" [value]="true">
                      {{ 'section-services.peb-partiel.yes' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div id="peb-partiel" *ngIf="pebPartiel">
                  <div>
                    <label>
                      {{ 'section-services.peb-partiel.subtitle' | translate }}
                      <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                    </label>
                    <mat-form-field class="peb-partiel" appearance="outline">
                      <input
                        matInput
                        #pebPartielNumber
                        (blur)="addPebNb(pebPartielNumber.value)"
                        [value]="pebNb"
                        placeholder="20190313001111"
                        name="peb-partiel"
                      />
                    </mat-form-field>
                  </div>
                  <div>
                    <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                    <button class="btn btn-default" (click)="addPebPartiel()">
                      {{ 'section-services.peb-partiel.option-action' | translate }}
                    </button>
                  </div>
                </div>
              </li>
              <li *ngIf="hasElec">
                <label>{{ 'section-services.elec.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbElec | async">
                    <mat-option
                      *ngFor="let elecOption of elecOptions"
                      (click)="setElecSupp(elecOption)"
                      [value]="elecOption"
                    >
                      {{ elecOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbGaz | async">
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="setGazSupp(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.iso' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbOfIso | async">
                    <mat-option (click)="dataService.setPlanIso(0)" [value]="0">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="dataService.setPlanIso(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasCiterne">
                <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="citerneType">
                    <mat-option (click)="setService(9)" [value]="9">
                      {{ 'section-services.citerne.under' | translate }}
                    </mat-option>
                    <mat-option (click)="setService(6)" [value]="6">
                      {{ 'section-services.citerne.aerial' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
            </ul>
          </mat-expansion-panel>
          <button
            *ngIf="($immoOrCtrlSelected | async) !== 1"
            id="show-all-services"
            class="btn btn-primary"
            (click)="toggleShowMoreServices(!showMoreServices)"
          >
            {{
              showMoreServices
                ? ('homepage.section-services.show-less' | translate)
                : isHainautOrNamurorNVN
                ? ('MDN-hainaut-namur-nvn.homepage.section-services.show-more' | translate)
                : ('homepage.section-services.show-more' | translate)
            }}
          </button>

          <!-- Section aide à la vente -->
          <mat-expansion-panel
            *ngIf="
              (((($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
                ($selectedEstateStyle | async) !== 2 &&
                ($filteredImmoServices | async)?.length > 0) ||
                (($selectedEstateStyle | async) === 3 && ($filteredImmoServices | async)?.length > 0)) &&
              ($immoOrCtrlSelected | async) > 0
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.immo' | translate }}
                  <small>({{ howManyImmoSelected }}/{{ ($filteredImmoServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredImmoServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <div class="tooltip-more">
                  <button
                    *ngIf="displayDetailsBtn(service.id)"
                    (click)="openDetailsDialog(service.id, $event)"
                    class="details-dialog-trigger btn-link tooltip-more-trigger"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Section panneaux -->
          <mat-expansion-panel
            *ngIf="
              ($filteredPanneauxServices | async)?.length > 0 &&
              (((($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
                ($selectedEstateStyle | async) !== 2) ||
                ($selectedEstateStyle | async) === 3) &&
              ($immoOrCtrlSelected | async) > 0
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.panel' | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredPanneauxServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.panels.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <div class="tooltip-more">
                  <button
                    *ngIf="displayDetailsBtn(service.id)"
                    (click)="openDetailsDialog(service.id, $event)"
                    class="details-dialog-trigger btn-link tooltip-more-trigger"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
            <!-- <ul *ngIf="hasPanel || hasBidditPanel" class="sub-questions">
              <li *ngIf="hasPanel">
                <label>
                  {{ 'section-services.panels.subtitle' | translate }}
                </label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="hasPanelSupport">
                    <mat-option (click)="setServiceNoRemove(33)" [value]="true">
                      {{ 'section-services.panels.support' | translate }}
                    </mat-option>
                    <mat-option (click)="setServiceNoRemove(32)" [value]="false">
                      {{ 'section-services.panels.to-stick' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasBidditPanel">
                <label>{{ 'section-services.panels.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="hasBidditPanelSupport">
                    <mat-option (click)="setServiceNoRemove(30)" [value]="true">
                      {{ 'section-services.panels.support' | translate }}
                    </mat-option>
                    <mat-option (click)="setServiceNoRemove(29)" [value]="false">
                      {{ 'section-services.panels.to-stick' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
            </ul>-->
          </mat-expansion-panel>
          <!-- Section MDN -->
          <!-- <mat-expansion-panel
            *ngIf="
              (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
              mdnService?.length > 0
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.mdn' | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of mdnService"
                [class]="'option ' + serviceClass(service.id)"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.mdn.' + service.id | translate }}</p>
              </div>
            </div>
          </mat-expansion-panel> -->
        </mat-accordion>
      </div>
    </section>
  </ng-container>
</div>

<div id="step-money" *ngIf="($hasDefinedRegionAndZipcode | async) && products?.length > 0">
  <div id="step-money-details">
    <ul id="product-list">
      <li class="product-list--element" *ngFor="let product of $products | async">
        <div class="name">
          {{ 'section-money.reductions.' + product.id | translate }}
          <span *ngIf="isLavenir(product.id)"> ({{ 'section-money.invoiced-by-lavenir' | translate }}) </span>
          <app-tooltip
            *ngIf="
              ('section-services.services-tooltip.' + product.id + '.content' | translate) !==
              'section-services.services-tooltip.' + product.id + '.content'
            "
            [title]="
              ('section-services.services-tooltip.' + product.id + '.title' | translate) !==
              'section-services.services-tooltip.' + product.id + '.title'
                ? ('section-services.services-tooltip.' + product.id + '.title' | translate)
                : null
            "
            [content]="
              ('section-services.services-tooltip.' + product.id + '.content' | translate) !==
              'section-services.services-tooltip.' + product.id + '.content'
                ? ('section-services.services-tooltip.' + product.id + '.content' | translate)
                : null
            "
            [emphasis]="
              ('section-services.services-tooltip.' + product.id + '.emphasis' | translate) !==
              'section-services.services-tooltip.' + product.id + '.emphasis'
                ? ('section-services.services-tooltip.' + product.id + '.emphasis' | translate)
                : null
            "
            [tooltipPosition]="'right'"
          ></app-tooltip>
        </div>
        <div *ngIf="product.price != '0'; else free" class="price">{{ product.price }} €</div>
        <ng-template #free>
          <div class="price">{{ 'section-money.free' | translate }}</div>
        </ng-template>
      </li>
    </ul>
    <div
      class="products-reduction"
      [ngClass]="{ 'show-details': reducMoreDetails }"
      *ngIf="!($urgence | async) && ($reduction | async) !== 0"
    >
      <div class="reduction-total">
        <div class="name">
          {{ 'section-money.reduction' | translate }}
          <button *ngIf="($commission | async) === 0" class="btn-link btn-more" (click)="reducMoreDetails = true">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button *ngIf="($commission | async) === 0" class="btn-link btn-less" (click)="reducMoreDetails = false">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <div class="price">- {{ reduction }} €</div>
      </div>
      <ul class="reduction-details" *ngIf="($commission | async) === 0">
        <li *ngFor="let reduction of $reductions | async">
          <div *ngIf="reduction.id !== 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }}
          </div>
          <div *ngIf="reduction.id === 1002">
            {{ 'section-money.reductions.' + reduction.id | translate }} "{{ promoCode }}"
          </div>
          <div>- {{ reduction.price }} €</div>
        </li>
      </ul>
    </div>
    <div class="commission" *ngIf="!succintStep1 && ($hasCommission | async) && !($urgence | async)">
      <div class="commission-action">
        <div>{{ 'section-money.commission' | translate }}</div>
        <div class="slider-container">
          <span>0</span>
          <mat-slider
            style="width: 100%"
            #commission
            (change)="setCommission(commission.value)"
            [value]="$commission | async"
            [min]="0"
            [max]="$reductionWithoutComm | async"
            [tickInterval]="10"
            [thumbLabel]="true"
          >
          </mat-slider
          ><span>{{ $reductionWithoutComm | async }}</span>
        </div>
      </div>
      <div>{{ $commission | async }} €</div>
    </div>
    <div *ngIf="showFinalOptions" [class]="'final-options ' + (succintStep1 ? 'is-summary' : '')">
      <div *ngIf="!($urgence | async) && !($isInstallateur | async)" class="internet-payment">
        <mat-checkbox (change)="onlinePayment()" [checked]="$onlinePayment | async">
          <span class="checkbox-label">{{ 'section-money.online-payment' | translate }}</span>
        </mat-checkbox>
      </div>
      <div *ngIf="!succintStep1 && canUrgence && !($isInstallateur | async)" class="urgence-action">
        <mat-checkbox (change)="urgence()" [checked]="$urgence | async">
          {{ 'section-money.urgence.label' | translate }}
        </mat-checkbox>
        <app-tooltip
          [tooltipPosition]="'top'"
          [title]="'section-money.urgence.tooltip-title' | translate"
          [content]="'section-money.urgence.tooltip-content' | translate"
          [emphasis]="'section-money.urgence.tooltip-emphasis' | translate"
        ></app-tooltip>
      </div>
      <div
        *ngIf="!succintStep1"
        [ngClass]="{
          'promo-reduction': true,
          valid: promoCode !== '' && ($promocodeID | async) !== '',
          invalid: promoCode !== '' && ($promocodeID | async) === ''
        }"
      >
        <span class="title">
          <fa-icon [icon]="['fas', 'check']" class="valid-icon"></fa-icon>
          {{ 'section-money.promo-code.label' | translate }}
          <app-tooltip
            [tooltipPosition]="'top'"
            [content]="'section-money.promo-code.tooltip' | translate"
          ></app-tooltip>
          <span class="valid-promo-code">
            {{ promoCode }}
          </span>
        </span>
        <div class="form-container">
          <mat-form-field class="promo-code-form" appearance="outline">
            <input
              matInput
              #pcode
              [value]="promoCode"
              placeholder="{{ 'section-money.promo-code.label' | translate }}"
            />
          </mat-form-field>
          <button class="btn" (click)="setPromoCode(pcode.value)">
            {{ 'section-money.promo-code.add' | translate }}
          </button>
          <span *ngIf="promoCode !== '' && ($promocodeID | async) === ''" class="error-msg caption">{{
            'section-money.promo-code.invalid' | translate
          }}</span>
        </div>
      </div>
      <div class="split-action">
        <app-split-invoice [showTitle]="false" [showDetails]="false"></app-split-invoice>
      </div>
    </div>
  </div>
  <app-step-money-result></app-step-money-result>
</div>

<div class="step-actions" *ngIf="($hasDefinedRegionAndZipcode | async) && products?.length > 0">
  <button class="btn btn-previous" (click)="toOrdering()">
    {{ 'navigation.reset' | translate }}
  </button>
  <div class="filler"></div>
  <button *ngIf="succintStep1" class="btn-warning" (click)="succintStep1 = false">
    {{ 'navigation.show-options' | translate }}
  </button>
  <button id="gt-next-step-1" matStepperNext class="btn-primary btn-next" (click)="nextStep()">
    {{ 'navigation.next' | translate }}
  </button>
</div>

<ng-template #agencyOrOther>
  <ng-container *ngIf="$isAgency | async; else installateurOrOther">
    <section class="section-situation">
      <h6>{{ 'section-situation.title' | translate }}</h6>
      <div class="section-situation-container">
        <label>{{ 'section-situation.question' | translate }}</label>
        <mat-form-field class="user-firstname" appearance="outline">
          <input
            matInput
            name="zip"
            placeholder="Ex: 1000"
            autocomplete="zip"
            [formControl]="situationControl"
            required
          />
          <mat-error>
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="$hasDefinedRegionAndZipcode | async" class="situation-result">
          <img [src]="imgUrlRegion($selectedRegion | async)" />
          <p>{{ 'section-situation.region.' + ($selectedRegion | async) | translate }}</p>
        </div>
      </div>

      <ng-container *ngIf="($selectedRegion | async) === 2 && !isAvailableZipFlanders; else sectionTypeTemp">
        <br />
        <div class="not-available">{{ 'section-situation.not-available' | translate }}</div>
      </ng-container>
    </section>
    <section *ngIf="false" class="section-situation immo" id="card-1" style="position: relative;">
      <div style="position: absolute; top: -125px; left: 0" #sectioImmoOrCtrl></div>
      <h6>{{ 'section-immo-or-ctrl.title' | translate }}</h6>
      <app-big-select
        class="client-type"
        [selected]="$immoOrCtrlSelected | async"
        [items]="immoOrCtrlOptions"
        [isMobile]="$isMobile | async"
        (valueChange)="setImmoOrCtrl($event, sectioImmoOrCtrl)"
        [translationString]="
          isHainautOrNamurorNVN ? 'section-immo-or-ctrl.options-mdn.' : 'section-immo-or-ctrl.options.'
        "
        [elementClass]="'clientType'"
      ></app-big-select>
    </section>
    <section class="section-type" id="card-2" *ngIf="$hasDefinedRegionAndZipcode | async" style="position: relative;">
      <div style="position: absolute; top: -125px; left: 0" #sectionType></div>
      <h6>
        <span>{{ 'section-type.title' | translate }}</span>
        <app-tooltip
          [title]="'section-type.title-tooltip.title' | translate"
          [content]="'section-type.title-tooltip.content' | translate"
          [emphasis]="'section-type.title-tooltip.emphasis' | translate"
        ></app-tooltip>
      </h6>
      <app-big-select
        class="estate-selector"
        [selected]="$selectedEstateStyle | async"
        [items]="estateStyles"
        [isMobile]="$isMobile | async"
        (valueChange)="setEstateStyle($event)"
        [translationString]="'section-type.style.'"
        [elementClass]="'estate'"
      ></app-big-select>
      <ul class="sub-questions">
        <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.house.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="houseControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let houseOption of houseOptions"
                (click)="setEstateType(houseOption.typeId, sectionType)"
                [value]="houseOption.typeId"
              >
                {{ 'estateTpes.' + houseOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.flat.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="appartControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let appartOption of appartOptions"
                (click)="setEstateType(appartOption.typeId, sectionType)"
                [value]="appartOption.typeId"
              >
                {{ 'estateTpes.' + appartOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li
          *ngIf="($selectedEstateStyle | async) === 2"
          [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'"
        >
          <label>{{ 'section-type.sub.building.label' | translate }}</label>
          <app-tooltip
            style="margin-right: 0.75rem;"
            [title]="'section-type.sub.building.title-tooltip.title' | translate"
            [content]="'section-type.sub.building.title-tooltip.content' | translate"
            [emphasis]="'section-type.sub.building.title-tooltip.emphasis' | translate"
          ></app-tooltip>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="buildingControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let immeubleOption of immeubleOptions"
                (click)="setNbOfAppartments(immeubleOption.id, sectionType)"
                [value]="immeubleOption.id"
              >
                {{ immeubleOption.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{
              'inputs.select-required' | translate
            }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 4" [class]="nonResControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.non-res' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="nonResControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let nonResOption of nonResOptions"
                (click)="setEstateType(nonResOption.typeId, sectionType)"
                [value]="nonResOption.typeId"
              >
                {{ 'estateTpes.' + nonResOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="nonResControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
      </ul>
    </section>
    <section
      class="section-services"
      id="card-3"
      *ngIf="
        (($hasDefinedRegionAndZipcode | async) && ($selectedEstateType | async) !== -1) ||
        (($hasDefinedRegionAndZipcode | async) &&
          ($selectedEstateType | async) !== -1 &&
          ($selectedEstateStyle | async) !== -1 &&
          ($nbOfAppartments | async) > 0)
      "
    >
      <h6>{{ 'section-services.title' | translate }}</h6>
      <!-- All pack here -->
      <div *ngIf="$immoPacksWithPrices | async as immoPacks" id="notary-pack-container">
        <div
          *ngFor="let immoPack of immoPacks"
          class="services-pack-element"
          [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
        >
          <header class="pack-header subtitle">
            {{ immoPack.name }}
            <span class="pack-pricing-big"
              >{{ (immoPack.totalWithReduc?.toFixed(2) | htva)?.toFixed(2) }}<small>€ HTVA</small></span
            >
            <!-- <span class="pack-pricing-small">{{ immoPack.totalWithReduc?.toFixed(2) }}€ TVAC</span> -->
            <span *ngIf="immoPack.proPackDisplay"
              ><small>{{ 'section-services.split-invoice' | translate }}</small></span
            >
          </header>
          <div class="pack-details open">
            <span *ngIf="immoPack.servicesNotary && immoPack.servicesNotary?.length > 0" class="pack-details-title">{{
              'section-services.service-certinergie' | translate
            }}</span>
            <ul>
              <li *ngFor="let serviceID of immoPack.services" class="pack-details-element">
                <img [src]="serviceImgUrl(null, serviceID)" />
                <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
                <button
                  *ngIf="displayDetailsBtn(serviceID)"
                  (click)="openDetailsDialog(serviceID)"
                  class="details-dialog-trigger btn-link"
                >
                  <div
                    [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                    [removeSVGAttributes]="['class']"
                    [setSVGAttributes]="svgPrimaryColor"
                  ></div>
                </button>
              </li>
              <ng-container *ngIf="packHasDescriptif(immoPack.services)">
                <li class="pack-details-element">
                  <img src="../../assets/images/service-plaquette.svg" />
                  <span>{{ 'section-money.reductions.-2' | translate }}</span>
                  <button
                    *ngIf="displayDetailsBtn(PRODUCTS.PLAQUETTE)"
                    (click)="openDetailsDialog(PRODUCTS.PLAQUETTE)"
                    class="details-dialog-trigger btn-link"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </li>
                <li class="pack-details-element">
                  <img src="../../assets/images/service-descriptif.svg" />
                  <span>{{ 'section-money.reductions.-8' | translate }}</span>
                  <button
                    *ngIf="displayDetailsBtn(PRODUCTS.AFFICHETTE)"
                    (click)="openDetailsDialog(PRODUCTS.AFFICHETTE)"
                    class="details-dialog-trigger btn-link"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>
          <div *ngIf="immoPack.servicesNotary && immoPack.servicesNotary?.length > 0" class="pack-details open">
            <span class="pack-details-title">Services maison du notariat</span>
            <ul>
              <li *ngFor="let serviceID of immoPack.servicesNotary" class="pack-details-element">
                <img src="../../assets/images/mdn-namur/mdn-icon.svg" />
                <span>{{ 'section-money.reductions.' + serviceID | translate }}</span>
              </li>
            </ul>
          </div>
          <button class="btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
            {{ 'section-services.choose-this-pack' | translate }}
          </button>
          <button class="btn-accent btn-selected" (click)="setImmoPack(immoPack.description)">
            {{ 'section-services.pack-selected' | translate }}
          </button>
        </div>
      </div>
      <!-- All carte here -->
      <div *ngIf="!($showPacks | async)" class="services-carte-container">
        <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
          <!-- Section publication -->
          <mat-expansion-panel
            *ngIf="
              (($userType | async) === UserTypes.Notaire || hasImmoAccess || hasImmoAccessProject) &&
              ($filteredPublicationsServices | async)?.length > 0
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.pub' | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredPublicationsServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
              </div>
            </div>
          </mat-expansion-panel>
          <!-- Section controle -->
          <mat-expansion-panel
            *ngIf="($filteredServices | async)?.length > 0"
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.control' | translate }}
                  <small>({{ howManyControlSelected }}/{{ ($filteredServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredServices | async"
                [class]="'option ' + serviceClass(service.id)"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">
                  {{ 'section-services.services.' + service.id | translate }}
                </p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <button
                  *ngIf="displayDetailsBtn(service.id)"
                  (click)="openDetailsDialog(service.id, $event)"
                  class="details-dialog-trigger btn-link"
                >
                  <div
                    [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                    [removeSVGAttributes]="['class']"
                    [setSVGAttributes]="svgPrimaryColor"
                  ></div>
                </button>
              </div>
            </div>
            <ul
              *ngIf="hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1)"
              class="sub-questions"
            >
              <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
                <label>{{ 'section-services.peb-partiel.title' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="pebPartiel">
                    <mat-option (click)="setPebPartiel(false)" [value]="false">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option (click)="setPebPartiel(true)" [value]="true">
                      {{ 'section-services.peb-partiel.yes' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div id="peb-partiel" *ngIf="pebPartiel">
                  <div>
                    <label>
                      {{ 'section-services.peb-partiel.subtitle' | translate }}
                      <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                    </label>
                    <mat-form-field class="peb-partiel" appearance="outline">
                      <input
                        matInput
                        #pebPartielNumber
                        (blur)="addPebNb(pebPartielNumber.value)"
                        [value]="pebNb"
                        placeholder="20190313001111"
                        name="peb-partiel"
                      />
                    </mat-form-field>
                  </div>
                  <div>
                    <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                    <button class="btn btn-default" (click)="addPebPartiel()">
                      {{ 'section-services.peb-partiel.option-action' | translate }}
                    </button>
                  </div>
                </div>
              </li>
              <li *ngIf="hasElec">
                <label>{{ 'section-services.elec.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbElec | async">
                    <mat-option
                      *ngFor="let elecOption of elecOptions"
                      (click)="setElecSupp(elecOption)"
                      [value]="elecOption"
                    >
                      {{ elecOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbGaz | async">
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="setGazSupp(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.iso' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbOfIso | async">
                    <mat-option (click)="dataService.setPlanIso(0)" [value]="0">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="dataService.setPlanIso(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasCiterne">
                <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="citerneType">
                    <mat-option (click)="setService(9)" [value]="9">
                      {{ 'section-services.citerne.under' | translate }}
                    </mat-option>
                    <mat-option (click)="setService(6)" [value]="6">
                      {{ 'section-services.citerne.aerial' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
            </ul>
          </mat-expansion-panel>
          <button
            *ngIf="($immoOrCtrlSelected | async) !== 1"
            id="show-all-services"
            class="btn btn-primary"
            (click)="toggleShowMoreServices(!showMoreServices)"
          >
            {{
              showMoreServices
                ? ('homepage.section-services.show-less' | translate)
                : isHainautOrNamurorNVN
                ? ('MDN-hainaut-namur-nvn.homepage.section-services.show-more' | translate)
                : ('homepage.section-services.show-more' | translate)
            }}
          </button>

          <!-- Section aide à la vente -->
          <mat-expansion-panel
            *ngIf="
              (($immoOrCtrlSelected | async) === 1 || showMoreServices) &&
              (((($userType | async) === UserTypes.Agence || hasImmoAccess || hasImmoAccessProject) &&
                ($selectedEstateStyle | async) !== 2 &&
                ($filteredImmoServices | async)?.length > 0) ||
                (($selectedEstateStyle | async) === 3 && ($filteredImmoServices | async)?.length > 0))
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.immo' | translate }}
                  <small>({{ howManyImmoSelected }}/{{ ($filteredImmoServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredImmoServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <div class="tooltip-more">
                  <button
                    *ngIf="displayDetailsBtn(service.id)"
                    (click)="openDetailsDialog(service.id, $event)"
                    class="details-dialog-trigger btn-link tooltip-more-trigger"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <!-- Section panneaux -->
          <!-- <mat-expansion-panel
            *ngIf="
              ($filteredPanneauxServices | async)?.length > 0 &&
              (((($userType | async) === UserTypes.Agence || hasImmoAccess || hasImmoAccessProject) &&
                ($selectedEstateStyle | async) !== 2) ||
                ($selectedEstateStyle | async) === 3)
            "
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.panel' | translate }}
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredPanneauxServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.panels.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
                <div class="tooltip-more">
                  <button
                    *ngIf="displayDetailsBtn(service.id)"
                    (click)="openDetailsDialog(service.id, $event)"
                    class="details-dialog-trigger btn-link tooltip-more-trigger"
                  >
                    <div
                      [inlineSVG]="'../../assets/images/icon-details-trigger.svg'"
                      [removeSVGAttributes]="['class']"
                      [setSVGAttributes]="svgPrimaryColor"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </mat-expansion-panel> -->
        </mat-accordion>
      </div>
    </section>
  </ng-container>
</ng-template>

<!-- Installateur flow -->
<ng-template #installateurOrOther>
  <ng-container *ngIf="$isInstallateur | async; else other">
    <section class="section-situation">
      <h6>{{ 'section-situation.title' | translate }}</h6>
      <div class="section-situation-container">
        <label>{{ 'section-situation.question' | translate }}</label>
        <mat-form-field class="user-firstname" appearance="outline">
          <input
            matInput
            name="zip"
            placeholder="Ex: 1000"
            autocomplete="zip"
            [formControl]="situationControl"
            required
          />
          <mat-error>
            {{ 'error-messages.required-blank' | translate }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="$hasDefinedRegionAndZipcode | async" class="situation-result">
          <img [src]="imgUrlRegion($selectedRegion | async)" />
          <p>{{ 'section-situation.region.' + ($selectedRegion | async) | translate }}</p>
        </div>
      </div>

      <ng-container *ngIf="($selectedRegion | async) === 2 && !isAvailableZipFlanders; else sectionTypeTemp">
        <br />
        <div class="not-available">{{ 'section-situation.not-available' | translate }}</div>
      </ng-container>
    </section>

    <section class="section-type" id="card-2" *ngIf="$hasDefinedRegionAndZipcode | async">
      <div style="position: absolute; top: -125px; left: 0" #sectionType></div>
      <h6>
        <span>{{ 'section-type.title' | translate }}</span>
        <app-tooltip
          [title]="'section-type.title-tooltip.title' | translate"
          [content]="'section-type.title-tooltip.content' | translate"
          [emphasis]="'section-type.title-tooltip.emphasis' | translate"
        ></app-tooltip>
      </h6>
      <app-big-select
        class="estate-selector"
        [selected]="$selectedEstateStyle | async"
        [items]="estateStyles"
        [isMobile]="$isMobile | async"
        (valueChange)="setEstateStyle($event)"
        [translationString]="'section-type.style.'"
        [elementClass]="'estate'"
      ></app-big-select>
      <ul class="sub-questions">
        <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.house.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="houseControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let houseOption of houseOptions"
                (click)="setEstateType(houseOption.typeId, sectionType)"
                [value]="houseOption.typeId"
              >
                {{ 'estateTpes.' + houseOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.flat.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="appartControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let appartOption of appartOptions"
                (click)="setEstateType(appartOption.typeId, sectionType)"
                [value]="appartOption.typeId"
              >
                {{ 'estateTpes.' + appartOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li
          *ngIf="($selectedEstateStyle | async) === 2"
          [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'"
        >
          <label>{{ 'section-type.sub.building.label' | translate }}</label>
          <app-tooltip
            style="margin-right: 0.75rem;"
            [title]="'section-type.sub.building.title-tooltip.title' | translate"
            [content]="'section-type.sub.building.title-tooltip.content' | translate"
            [emphasis]="'section-type.sub.building.title-tooltip.emphasis' | translate"
          ></app-tooltip>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="buildingControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let immeubleOption of immeubleOptions"
                (click)="setNbOfAppartments(immeubleOption.id, sectionType)"
                [value]="immeubleOption.id"
              >
                {{ immeubleOption.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{
              'inputs.select-required' | translate
            }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 4" [class]="nonResControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.non-res' | translate }}</label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="nonResControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let nonResOption of nonResOptions"
                (click)="setEstateType(nonResOption.typeId, sectionType)"
                [value]="nonResOption.typeId"
              >
                {{ 'estateTpes.' + nonResOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="nonResControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
      </ul>
    </section>
    <section
      class="section-services"
      id="card-3"
      *ngIf="
        (($hasDefinedRegionAndZipcode | async) && ($selectedEstateType | async) !== -1) ||
        (($hasDefinedRegionAndZipcode | async) &&
          ($selectedEstateType | async) !== -1 &&
          ($selectedEstateStyle | async) !== -1 &&
          ($nbOfAppartments | async) > 0)
      "
    >
      <header>
        <h6>{{ 'section-services.title' | translate }}</h6>
        <mat-button-toggle-group *ngIf="!succintStep1" #group="matButtonToggleGroup">
          <mat-button-toggle (click)="setShowPacks(false)" [checked]="!($showPacks | async)">
            {{ 'section-services.btn-toggle.alacarte' | translate }}
          </mat-button-toggle>
          <mat-button-toggle (click)="setShowPacks(true)" [checked]="$showPacks | async">
            <div class="pack-label-container">
              {{ 'section-services.btn-toggle.packs' | translate }}
              <span class="toggle-promo">PROMO</span>
            </div>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </header>
      <!-- All pack here -->
      <div class="services-pack-container" *ngIf="($showPacks | async) && ($selectedEstateStyle | async) !== -1">
        <div
          class="services-pack-element"
          [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
          *ngFor="let immoPack of $immoPacksWithPrices | async | sortby: 'description'"
        >
          <header class="pack-header subtitle">
            {{ 'section-services.packs.' + immoPack.description | translate }}
          </header>
          <div class="pack-pricing" *ngIf="($userType | async) === UserTypes.Particulier; else notPart">
            <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
            <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
            <span class="pack-pricing-reduction">
              {{ 'section-services.insteadof' | translate }}
              <span> {{ immoPack.servicesPrice }} </span>€
            </span>
          </div>
          <ng-template #notPart>
            <div class="pack-pricing">
              <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
              <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
              <span class="pack-pricing-reduction">
                {{ 'section-services.insteadof' | translate }}
                <span> {{ immoPack.servicesPrice }} </span>€
              </span>
            </div>
          </ng-template>
          <div class="pack-details" [ngClass]="{ open: immoPack.details }">
            <ul>
              <li class="pack-details-element" *ngFor="let serv of immoPack.servicesInfo">
                <img
                  [src]="serviceImgUrl(serv.fileName)"
                  matTooltip="{{ 'section-services.services.' + serv.id | translate }}"
                  matTooltipPosition="below"
                />
                <span>{{ 'section-services.services.' + serv.id | translate }}</span>
              </li>
            </ul>
            <button class="btn-link btn-more" (click)="immoPack.details = true">
              ({{ 'section-services.details-show' | translate }})
            </button>
            <button class="btn-link btn-less" (click)="immoPack.details = false">
              ({{ 'section-services.details-hide' | translate }})
            </button>
          </div>
          <button class="btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
            {{ 'section-services.choose-this-pack' | translate }}
          </button>
          <span class="btn-accent btn-selected">
            {{ 'section-services.pack-selected' | translate }}
          </span>
        </div>
      </div>
      <!-- All carte here -->
      <div *ngIf="!($showPacks | async)" class="services-carte-container">
        <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
          <!-- Section controle -->
          <mat-expansion-panel
            *ngIf="($filteredServices | async)?.length > 0"
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.control' | translate }}
                  <small>({{ howManyControlSelected }}/{{ ($filteredServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredServices | async"
                [class]="'option ' + serviceClass(service.id)"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">
                  {{ 'section-services.services.' + service.id | translate }}
                </p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
              </div>
            </div>
            <ul
              *ngIf="hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1)"
              class="sub-questions"
            >
              <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
                <label>
                  {{ 'section-services.peb-partiel.title' | translate }}
                </label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="pebPartiel">
                    <mat-option (click)="setPebPartiel(false)" [value]="false">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option (click)="setPebPartiel(true)" [value]="true">
                      {{ 'section-services.peb-partiel.yes' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="peb-partiel" *ngIf="pebPartiel">
                  <div>
                    <label
                      >{{ 'section-services.peb-partiel.subtitle' | translate }}
                      <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                    </label>
                    <mat-form-field class="peb-partiel" appearance="outline">
                      <input
                        matInput
                        #pebPartielNumber
                        (blur)="addPebNb(pebPartielNumber.value)"
                        [value]="pebNb"
                        placeholder="20190313001111"
                        name="peb-partiel"
                      />
                    </mat-form-field>
                  </div>
                  <div>
                    <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                    <button class="btn" (click)="addPebPartiel()">
                      {{ 'section-services.peb-partiel.option-action' | translate }}
                    </button>
                  </div>
                </div>
              </li>
              <li *ngIf="hasElec">
                <label>{{ 'section-services.elec.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbElec | async">
                    <mat-option
                      *ngFor="let elecOption of elecOptions"
                      (click)="setElecSupp(elecOption)"
                      [value]="elecOption"
                    >
                      {{ elecOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbGaz | async">
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="setGazSupp(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasGaz">
                <label>{{ 'section-services.gaz.iso' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="$nbOfIso | async">
                    <mat-option (click)="dataService.setPlanIso(0)" [value]="0">
                      {{ 'section-services.peb-partiel.no' | translate }}
                    </mat-option>
                    <mat-option
                      *ngFor="let gazOption of gazOptions"
                      (click)="dataService.setPlanIso(gazOption)"
                      [value]="gazOption"
                    >
                      {{ gazOption }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
              <li *ngIf="hasCiterne">
                <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
                <mat-form-field appearance="outline">
                  <mat-select [value]="citerneType">
                    <mat-option (click)="setService(9)" [value]="9">
                      {{ 'section-services.citerne.under' | translate }}
                    </mat-option>
                    <mat-option (click)="setService(6)" [value]="6">
                      {{ 'section-services.citerne.aerial' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </li>
            </ul>
          </mat-expansion-panel>
          <!-- Section aide à la vente -->
          <mat-expansion-panel
            *ngIf="($selectedEstateStyle | async) !== 2 && ($filteredImmoServices | async)?.length > 0"
            class="services-carte-panel"
            [expanded]="true"
          >
            <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
              <mat-panel-title>
                <p class="subtitle">
                  {{ 'section-services.alacarte.immo' | translate }}
                  <small>({{ howManyImmoSelected }}/{{ ($filteredImmoServices | async)?.length }})</small>
                </p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="services-carte-panel-content options-container">
              <div
                *ngFor="let service of $filteredImmoServices | async"
                [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
                (click)="setService(service.id)"
              >
                <picture>
                  <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
                </picture>
                <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
                <app-tooltip
                  *ngIf="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                  "
                  [title]="
                    ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.title'
                      ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                      : null
                  "
                  [content]="
                    ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.content'
                      ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                      : null
                  "
                  [emphasis]="
                    ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                    'section-services.services-tooltip.' + service.id + '.emphasis'
                      ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                      : null
                  "
                ></app-tooltip>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </section>
  </ng-container>
</ng-template>

<!-- Particulier flow -->
<ng-template #other>
  <section *ngIf="!succintStep1 && !($isLoggedIn | async)" class="section-situation" id="card-1">
    <h6>{{ 'section-client.particulier-title' | translate }}</h6>
    <app-big-select
      class="client-type"
      [selected]="$clientType | async"
      [items]="clientTypes"
      [isMobile]="$isMobile | async"
      (valueChange)="setClientType($event)"
      [translationString]="'section-user.disco.client-type.'"
      [elementClass]="'clientType'"
    ></app-big-select>
  </section>
  <section *ngIf="($clientType | async) != -1 || ($isLoggedIn | async)" class="section-reason">
    <h6>{{ 'section-reason.title' | translate }}</h6>
    <app-big-select
      class="reason-type"
      [selected]="$reason | async"
      [items]="reasons"
      [isMobile]="$isMobile | async"
      (valueChange)="setReasonType($event)"
      [translationString]="'section-reason.reason-type.'"
      [elementClass]="'reasonType'"
    ></app-big-select>
  </section>
  <section *ngIf="($reason | async) != -1" class="section-situation">
    <h6>{{ 'section-situation.particulier-title' | translate }}</h6>
    <div class="section-situation-container">
      <label>{{ 'section-situation.question' | translate }}</label>
      <mat-form-field class="user-firstname" appearance="outline">
        <input
          matInput
          name="zip"
          placeholder="Ex: 1000"
          autocomplete="zip"
          [formControl]="situationControl"
          required
        />
        <mat-error>
          {{ 'error-messages.required-blank' | translate }}
        </mat-error>
      </mat-form-field>
      <div *ngIf="$hasDefinedRegionAndZipcode | async" class="situation-result">
        <img [src]="imgUrlRegion($selectedRegion | async)" />
        <p>{{ 'section-situation.region.' + ($selectedRegion | async) | translate }}</p>
      </div>
    </div>
  </section>
  <section
    class="section-type"
    id="card-2"
    *ngIf="$hasDefinedRegionAndZipcode && !(succintStep1 && ($selectedEstateType | async) === 0)"
  >
    <div style="position: absolute; top: -125px; left: 0" #sectionType></div>
    <ng-container *ngIf="($selectedRegion | async) === 2 && !isAvailableZipFlanders; else sectionTypeTemp">
      <div class="not-available">{{ 'section-situation.not-available' | translate }}</div>
    </ng-container>
    <ng-template #sectionTypeTemp>
      <h6>
        <span>{{ 'section-type.particulier-title' | translate }}</span>
        <app-tooltip
          [title]="'section-type.title-tooltip.title' | translate"
          [content]="'section-type.title-tooltip.content' | translate"
          [emphasis]="'section-type.title-tooltip.emphasis' | translate"
        ></app-tooltip>
      </h6>
      <app-big-select
        class="estate-selector"
        [selected]="$selectedEstateStyle | async"
        [items]="estateStylesParticulier"
        [isMobile]="$isMobile | async"
        (valueChange)="setEstateStyle($event)"
        [translationString]="'section-type.style.'"
        [elementClass]="'estate'"
      ></app-big-select>
      <ul class="sub-questions">
        <li *ngIf="($selectedEstateStyle | async) === 0" [class]="houseControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.house.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="houseControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let houseOption of houseOptions"
                (click)="setEstateType(houseOption.typeId, sectionType)"
                [value]="houseOption.typeId"
              >
                {{ 'estateTpes.' + houseOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="houseControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 1" [class]="appartControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.sub.flat.label' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="appartControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let appartOption of appartOptions"
                (click)="setEstateType(appartOption.typeId, sectionType)"
                [value]="appartOption.typeId"
              >
                {{ 'estateTpes.' + appartOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="appartControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li
          *ngIf="($selectedEstateStyle | async) === 2"
          [class]="buildingControl.hasError('isEmpty') ? '' : 'selected'"
        >
          <label>{{ 'section-type.sub.building.label' | translate }}</label>
          <app-tooltip
            style="margin-right: 0.75rem;"
            [title]="'section-type.sub.building.title-tooltip.title' | translate"
            [content]="'section-type.sub.building.title-tooltip.content' | translate"
            [emphasis]="'section-type.sub.building.title-tooltip.emphasis' | translate"
          ></app-tooltip>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="buildingControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let immeubleOption of immeubleOptions"
                (click)="setNbOfAppartments(immeubleOption.id, sectionType)"
                [value]="immeubleOption.id"
              >
                {{ immeubleOption.text }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="buildingControl.hasError('isEmpty')">{{
              'inputs.select-required' | translate
            }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
        <li *ngIf="($selectedEstateStyle | async) === 4" [class]="nonResControl.hasError('isEmpty') ? '' : 'selected'">
          <label>{{ 'section-type.non-res' | translate }} </label>
          <mat-form-field appearance="outline">
            <mat-select [formControl]="nonResControl">
              <mat-option (click)="setEstateType(-1, sectionType)" [value]="-1">--</mat-option>
              <mat-option
                *ngFor="let nonResOption of nonResOptions"
                (click)="setEstateType(nonResOption.typeId, sectionType)"
                [value]="nonResOption.typeId"
              >
                {{ 'estateTpes.' + nonResOption.typeId | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="nonResControl.hasError('isEmpty')">{{ 'inputs.select-required' | translate }}</mat-error>
          </mat-form-field>
          <fa-icon [icon]="['fas', 'check-circle']" class="icon-selected"></fa-icon>
        </li>
      </ul>
    </ng-template>
  </section>
  <section
    class="section-services"
    id="card-3"
    *ngIf="
      (($hasDefinedRegionAndZipcode | async) && ($selectedEstateType | async) > -1) ||
      (($hasDefinedRegionAndZipcode | async) &&
        ($selectedEstateType | async) > -1 &&
        ($selectedEstateStyle | async) !== -1 &&
        ($nbOfAppartments | async) > 0)
    "
  >
    <header>
      <h6>
        {{ (($showMoreServices | async) ? 'section-services.title' : 'section-services.title-part') | translate }}
      </h6>
      <mat-button-toggle-group
        *ngIf="!succintStep1 && ($immoPacksWithPrices | async)?.length > 0"
        #group="matButtonToggleGroup"
      >
        <mat-button-toggle (click)="setShowPacks(false)" [checked]="!($showPacks | async)">
          {{ 'section-services.btn-toggle.alacarte' | translate }}
        </mat-button-toggle>
        <mat-button-toggle (click)="setShowPacks(true)" [checked]="$showPacks | async">
          <div class="pack-label-container">
            {{ 'section-services.btn-toggle.packs' | translate }}
            <span class="toggle-promo">PROMO</span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </header>
    <!-- All pack here -->
    <div class="services-pack-container" *ngIf="($showPacks | async) && ($selectedEstateStyle | async) !== -1">
      <div
        class="services-pack-element"
        [ngClass]="{ selected: ($selectedImmoPack | async) == immoPack.description }"
        *ngFor="let immoPack of $immoPacksWithPrices | async | sortby: 'description'"
      >
        <header class="pack-header subtitle">{{ 'section-services.packs.' + immoPack.description | translate }}</header>
        <div class="pack-pricing" *ngIf="($userType | async) === UserTypes.Particulier; else notPart">
          <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
          <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
          <span class="pack-pricing-reduction">
            {{ 'section-services.insteadof' | translate }}
            <span> {{ immoPack.servicesPrice }} </span>€
          </span>
        </div>
        <ng-template #notPart>
          <div class="pack-pricing">
            <span class="pack-pricing-promo">- {{ immoPack.servicesPrice - immoPack.totalWithReduc }}€</span>
            <span class="pack-pricing-big">{{ immoPack.totalWithReduc }}<small>€</small></span>
            <span class="pack-pricing-reduction">
              {{ 'section-services.insteadof' | translate }}
              <span> {{ immoPack.servicesPrice }} </span>€
            </span>
          </div>
        </ng-template>
        <div class="pack-details" [ngClass]="{ open: immoPack.details }">
          <ul>
            <li class="pack-details-element" *ngFor="let serv of immoPack.servicesInfo">
              <img
                [src]="serviceImgUrl(serv.fileName)"
                matTooltip="{{ 'section-services.services.' + serv.id | translate }}"
                matTooltipPosition="below"
              />
              <span>{{ 'section-services.services.' + serv.id | translate }}</span>
            </li>
          </ul>
          <button class="btn-link btn-more" (click)="toggleImmoPackDetails(immoPack.id)">
            ({{ 'section-services.details-show' | translate }})
          </button>
          <button class="btn-link btn-less" (click)="toggleImmoPackDetails(immoPack.id)">
            ({{ 'section-services.details-hide' | translate }})
          </button>
        </div>
        <button class="btn-primary btn-selection" (click)="setImmoPack(immoPack.description)">
          {{ 'section-services.choose-this-pack' | translate }}
        </button>
        <span class="btn-accent btn-selected">
          {{ 'section-services.pack-selected' | translate }}
        </span>
      </div>
    </div>
    <!-- All carte here -->
    <div *ngIf="!($showPacks | async) || ($immoPacksWithPrices | async)?.length === 0" class="services-carte-container">
      <mat-accordion [displayMode]="'flat'" [multi]="true" class="services-carte-accordeon">
        <!-- Section controle -->
        <mat-expansion-panel
          *ngIf="($filteredServices | async)?.length > 0"
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{
                  (($showMoreServices | async)
                    ? 'section-services.alacarte.control'
                    : 'section-services.alacarte.control-mandatory') | translate
                }}
                <small>({{ howManyControlSelected }}/{{ ($filteredServices | async)?.length }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of $filteredServices | async"
              [class]="'option ' + serviceClass(service.id)"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">
                {{ 'section-services.services.' + service.id | translate }}
              </p>
              <app-tooltip
                *ngIf="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.title'
                    ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                    ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
          <ul
            *ngIf="hasCiterne || hasElec || hasGaz || (hasPeb && ($selectedEstateStyle | async) === 1)"
            class="sub-questions"
          >
            <li *ngIf="hasPeb && ($selectedEstateStyle | async) === 1 && ($selectedRegion | async) === 0">
              <label>
                {{ 'section-services.peb-partiel.title' | translate }}
              </label>
              <mat-form-field appearance="outline">
                <mat-select [value]="pebPartiel">
                  <mat-option (click)="setPebPartiel(false)" [value]="false">
                    {{ 'section-services.peb-partiel.no' | translate }}
                  </mat-option>
                  <mat-option (click)="setPebPartiel(true)" [value]="true">
                    {{ 'section-services.peb-partiel.yes' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="peb-partiel" *ngIf="pebPartiel">
                <div>
                  <label
                    >{{ 'section-services.peb-partiel.subtitle' | translate }}
                    <app-tooltip [content]="'section-services.peb-partiel.tooltip' | translate"></app-tooltip>
                  </label>
                  <mat-form-field class="peb-partiel" appearance="outline">
                    <input
                      matInput
                      #pebPartielNumber
                      (blur)="addPebNb(pebPartielNumber.value)"
                      [value]="pebNb"
                      placeholder="20190313001111"
                      name="peb-partiel"
                    />
                  </mat-form-field>
                </div>
                <div>
                  <span>{{ 'section-services.peb-partiel.option' | translate }}</span>
                  <button class="btn" (click)="addPebPartiel()">
                    {{ 'section-services.peb-partiel.option-action' | translate }}
                  </button>
                </div>
              </div>
            </li>
            <li *ngIf="hasElec">
              <label>{{ 'section-services.elec.subtitle' | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-select [value]="$nbElec | async">
                  <mat-option
                    *ngFor="let elecOption of elecOptions"
                    (click)="setElecSupp(elecOption)"
                    [value]="elecOption"
                  >
                    {{ elecOption }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </li>
            <li *ngIf="hasGaz">
              <label>{{ 'section-services.gaz.subtitle' | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-select [value]="$nbGaz | async">
                  <mat-option *ngFor="let gazOption of gazOptions" (click)="setGazSupp(gazOption)" [value]="gazOption">
                    {{ gazOption }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </li>
            <li *ngIf="hasGaz">
              <label>{{ 'section-services.gaz.iso' | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-select [value]="$nbOfIso | async">
                  <mat-option (click)="dataService.setPlanIso(0)" [value]="0">
                    {{ 'section-services.peb-partiel.no' | translate }}
                  </mat-option>
                  <mat-option
                    *ngFor="let gazOption of gazOptions"
                    (click)="dataService.setPlanIso(gazOption)"
                    [value]="gazOption"
                  >
                    {{ gazOption }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </li>
            <li *ngIf="hasCiterne">
              <label>{{ 'section-services.citerne.subtitle' | translate }}</label>
              <mat-form-field appearance="outline">
                <mat-select [value]="citerneType">
                  <mat-option (click)="setService(9)" [value]="9">
                    {{ 'section-services.citerne.under' | translate }}
                  </mat-option>
                  <mat-option (click)="setService(6)" [value]="6">
                    {{ 'section-services.citerne.aerial' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </li>
          </ul>
          <button
            *ngIf="($selectedEstateStyle | async) !== 4"
            id="show-all-services"
            [class]="showMoreServices ? 'btn' : 'btn-primary'"
            (click)="toggleShowMoreServices(!showMoreServices)"
          >
            {{
              showMoreServices
                ? ('homepage.section-services.show-less' | translate)
                : isHainautOrNamurorNVN
                ? ('MDN-hainaut-namur-nvn.homepage.section-services.show-more' | translate)
                : ('homepage.section-services.show-more' | translate)
            }}
          </button>
        </mat-expansion-panel>
        <!-- Section aide à la vente -->
        <mat-expansion-panel
          *ngIf="($selectedEstateStyle | async) !== 2 && ($filteredImmoServices | async)?.length > 0"
          class="services-carte-panel"
          [expanded]="true"
        >
          <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
            <mat-panel-title>
              <p class="subtitle">
                {{ 'section-services.alacarte.immo' | translate }}
                <small>({{ howManyImmoSelected }}/{{ ($filteredImmoServices | async)?.length }})</small>
              </p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="services-carte-panel-content options-container">
            <div
              *ngFor="let service of $filteredImmoServices | async"
              [class]="'option ' + serviceClass(service.id) + (service.free ? ' free-service' : '')"
              (click)="setService(service.id)"
            >
              <picture>
                <img [src]="serviceImgUrl(service.fileName, service.id)" class="service-img" />
              </picture>
              <p class="service-txt">{{ 'section-services.services.' + service.id | translate }}</p>
              <app-tooltip
                *ngIf="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                "
                [title]="
                  ('section-services.services-tooltip.' + service.id + '.title' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.title'
                    ? ('section-services.services-tooltip.' + service.id + '.title' | translate)
                    : null
                "
                [content]="
                  ('section-services.services-tooltip.' + service.id + '.content' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.content'
                    ? ('section-services.services-tooltip.' + service.id + '.content' | translate)
                    : null
                "
                [emphasis]="
                  ('section-services.services-tooltip.' + service.id + '.emphasis' | translate) !==
                  'section-services.services-tooltip.' + service.id + '.emphasis'
                    ? ('section-services.services-tooltip.' + service.id + '.emphasis' | translate)
                    : null
                "
              ></app-tooltip>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
</ng-template>
