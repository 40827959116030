import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAdressDialogComponent } from "../add-adress-dialog/add-adress-dialog.component"
import { DataService } from '../../../core/services/data.service';
import { DeleteAdressComponent } from '../delete-adress/delete-adress.component'
import { EditAdressDialogComponent } from '../edit-adress-dialog/edit-adress-dialog.component'

@Component({
  selector: 'app-add-adresses',
  templateUrl: './add-adresses.component.html',
  styleUrls: ['./add-adresses.component.scss']
})
export class AddAdressesComponent implements OnInit {
  constructor(public dialog: MatDialog, private dataService: DataService) { }
  @Input() profile;
  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!this.profile) {
      this.profile = {
        MainAddress: {},
        Addresses: [],
      };
    }
  }

  addNewAdress() {
    if (!this.profile) return; // Ensure profile data exists
    this.dialog.open(AddAdressDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog },
    });
  }

  editAdress(adress, id) {
    if (!adress || !id) return; // Guard against missing data
    this.dialog.open(EditAdressDialogComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog, profile: this.profile, adress, id },
    });
  }

  deleteAdress(id) {
    if (!id) return; // Ensure ID is valid
    this.dialog.open(DeleteAdressComponent, {
      panelClass: ['full-screen-dialog'],
      data: { dialog: this.dialog, id },
    });
  }
}
