<div id="dialog-container" *ngIf="!sent; else congrats">
  <header id="dialog-header">
    <h5>
      <img src="../../../assets/images/service-signs.svg" />
      {{ 'signs-request-dialog.title' | translate }}
    </h5>
  </header>
  <div id="dialog-intro">
    <p>{{ 'signs-request-dialog.content' | translate }}</p>
  </div>
  <div id="dialog-content">
    <div class="form-element">
      <label>{{ 'signs-request-dialog.type-of-sale' | translate }}</label>
      <mat-form-field appearance="outline">
        <mat-select (selectionChange)="SetDefaultQuantity()" [(ngModel)]="selectedPanel.venteType">
          <mat-option *ngFor="let item of ventes" [value]="item.id">
            {{ item.txt }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <ng-container *ngIf="selectedPanel.venteType === 1 || selectedPanel.venteType === 2">
      <div *ngIf="selectedPanel.venteType === 2">
        {{ txtVenteBiddit }}
      </div>
      <div *ngIf="selectedPanel.venteType === 1">
        {{ txtVenteGG }}
      </div>
      <div class="form-element">
        <label for="number">{{ 'signs-request-dialog.label' | translate }}</label>
        <mat-form-field name="nombre" appearance="outline">
          <input
            [(ngModel)]="selectedPanel.quantity"
            id="number"
            name="selected-quantity"
            matInput
            placeholder="{{ 'signs-request-dialog.placeholder' | translate }}"
            [min]="minQuantity"
            type="number"
          />
        </mat-form-field>
      </div>
      <ul id="info">
        <li>
          <span>
            <strong>{{ 'signs-request-dialog.info.price-label' | translate }}</strong>
          </span>
          <span>{{ computedPrice }} € HTVA</span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="selectedPanel.venteType === 0">
      {{ txtVentePub }}
    </ng-container>
    <div id="total">
      <span>
        <strong>{{ 'signs-request-dialog.info.total-label' | translate }}</strong>
      </span>
      <span>{{ totalPrice?.toFixed(2) }} € HTVA</span>
    </div>
  </div>
  <footer id="dialog-footer">
    <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button (click)="sendEmail()" class="btn btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </footer>
</div>

<ng-template #congrats>
  <div id="dialog-confirm">
    <picture>
      <fa-icon [icon]="['fas', 'check']"></fa-icon>
    </picture>
    <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
    <p>
      {{ 'specific-request-dialog.success-content' | translate }}
    </p>
    <button (click)="close()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
  </div>
</ng-template>
