<div class="step-content">
  <section>
    <h6>
      {{ 'step3.title' | translate }}
      <app-tooltip [tooltipPosition]="'bottom'" [content]="'step3.title-tooltip' | translate"></app-tooltip>
    </h6>
    <div class="adress-container">
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprio' }"
        (click)="SelectInvoice('toProprio')"
        *ngIf="($isLoggedIn | async) && !forceToPro"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner?.companyname }} {{ owner?.firstname }}
            {{ owner?.lastname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ address?.street }} {{ address?.number }}
            {{ address?.boxNumber }} {{ address?.apartmentNumber }}, {{ address?.zip }} {{ address?.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprioAndPro' }"
        (click)="SelectInvoice('toProprioAndPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum?.Notaire && !forceToPro"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner-co-notary' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner?.firstname }} {{ owner?.lastname }} C/o
            {{ user?.commercialname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user?.address?.street }} {{ user?.address?.number }}
            {{ user?.address?.boxNumber }} {{ user?.address?.apartmentNumber }},
            {{ user?.address?.zip }}
            {{ user?.address?.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toPro' }"
        (click)="SelectInvoice('toPro')"
        *ngIf="
          (($isLoggedIn | async) && (userType === UserTypeEnum?.Notaire || userType === UserTypeEnum?.Installateur)) ||
          forceToPro
        "
      >
        <header>
          <h6 class="subtitle">
            {{ (userType === UserTypeEnum?.Installateur ? 'step3.to-insta' : 'step3.to-notary') | translate }}
          </h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ user?.lastname }} {{ user?.firstname }}
            {{ user?.commercialname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user?.address?.street }} {{ user?.address?.number }}
            {{ user?.address?.boxNumber }} {{ user?.address?.apartmentNumber }},
            {{ user?.address?.zip }}
            {{ user?.address?.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprioAndPro' }"
        (click)="SelectInvoice('toProprioAndPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum?.Agence"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner-co-agency' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ owner?.firstname }} {{ owner?.lastname }} C/o
            {{ user?.commercialname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user?.address?.street }} {{ user?.address?.number }}
            {{ user?.address?.boxNumber }} {{ user?.address?.apartmentNumber }},
            {{ user?.address?.zip }}
            {{ user?.address?.city }}
          </p>
        </div>
      </button>
      <button
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toPro' }"
        (click)="SelectInvoice('toPro')"
        *ngIf="($isLoggedIn | async) && userType === UserTypeEnum?.Agence"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-agency' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span> {{ user?.commercialname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ user?.address?.street }} {{ user?.address?.number }}
            {{ user?.address?.boxNumber }} {{ user?.address?.apartmentNumber }},
            {{ user?.address?.zip }}
            {{ user?.address?.city }}
          </p>
        </div>
      </button>
      <button
        *ngIf="!($isLoggedIn | async) && !forceToPro"
        [ngClass]="{ btn: true, adress: true, selected: invoiceTo == 'toProprio' }"
        (click)="SelectInvoice('toProprio')"
      >
        <header>
          <h6 class="subtitle">{{ 'step3.to-owner' | translate }}</h6>
        </header>
        <div>
          <p>
            <span>{{ 'step3.fullname' | translate }}:</span>{{ userForm?.companyname }} {{ userForm?.firstname }}
            {{ userForm?.lastname }}
          </p>
          <p>
            <span>{{ 'step3.address' | translate }}:</span> {{ address?.street }} {{ address?.number }}
            {{ address?.boxNumber }} {{ address?.apartmentNumber }}, {{ address?.zip }} {{ address?.city }}
          </p>
        </div>
      </button>
    </div>
  </section>
  <section>
    <app-banner>{{ 'step3.info' | translate }}</app-banner>
  </section>
  <section>
    <app-split-invoice [showDetails]="false"></app-split-invoice>
  </section>
</div>
<div class="step-actions">
  <button class="btn btn-previous" matStepperPrevious>
    {{ 'navigation.previous' | translate }}
  </button>
  <button class="btn-primary btn-next" matStepperNext (click)="toStep4()">
    {{ 'navigation.next' | translate }}
  </button>
</div>
