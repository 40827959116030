<div #alert class="comissions-info-container">
  <app-banner>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeAlert()"></button>

    <div class="col-10">
      <ul>
        <li>
          <span>{{ 'comission.info1' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info2' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info3' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info4' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info5' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info6' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info7' | translate }}</span>
        </li>
        <li>
          <span>{{ 'comission.info8' | translate }}</span>
        </li>
      </ul>
    </div>
  </app-banner>
</div>
