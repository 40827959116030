import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from '../../../core/services/data.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile$: BehaviorSubject<any> = new BehaviorSubject(null); // Initialize with null for safety.

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService.getProfile().pipe(
      catchError((error) => {
        console.error('Error fetching profile:', error);
        return of(null); // Emit null or handle as needed.
      })
    ).subscribe(); // Ensures the method is called, even if subscription isn't needed directly.
    this.profile$ = this.dataService.profile$;
  }
}