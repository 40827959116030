import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FunctionType } from '../../../core/enums/function-type.enum'
import { TitleType } from '../../../core/enums/title.enum'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../core/services/data.service';
@Component({
  selector: 'app-edit-contact-dialog',
  templateUrl: './edit-contact-dialog.component.html',
  styleUrls: ['./edit-contact-dialog.component.scss']
})
export class EditContactDialogComponent implements OnInit {
  newContact: FormGroup;
  functionType = FunctionType;
  titleType = TitleType;
  displayError = false;
  constructor(private fb: FormBuilder, private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.newContact = this.fb?.group({
      Function: [this?.data?.contact.Function,],
      Title: [this?.data?.contact.Title,],
      Name: [this?.data?.contact.Name,],
      FirstName: [this?.data?.contact.FirstName,],
      TelNumber: [this?.data?.contact.TelNumber,],
      PhoneNumber: [this?.data?.contact.PhoneNumber,],
      Email: [this.data?.contact?.Email, [Validators.required, Validators.email],],
      UnsubscribedOrderInformations: [!this.data?.contact?.UnsubscribedOrderInformations,],
      UnsubscribedEmailReports: [!this.data?.contact?.UnsubscribedEmailReports,],
    });

  }
  editContact() {
    if (this?.newContact?.invalid) {
      this.displayError = true; // Show the error message if the form is invalid
      return; // Stop further execution
    }

    this.newContact?.get('UnsubscribedOrderInformations').setValue(!this.newContact?.get('UnsubscribedOrderInformations').value)
    this.newContact?.get('UnsubscribedEmailReports').setValue(!this.newContact?.get('UnsubscribedEmailReports').value)
    this.displayError = false;
    let toPatch = [];
    if (this.data.mainContact) {
      toPatch = [
        { "op": "replace", "path": "/Title", "value": this.newContact?.get('Title').value },
        { "op": "replace", "path": "/Name", "value": this.newContact?.get('Name').value },
        { "op": "replace", "path": "/FirstName", "value": this.newContact?.get('FirstName').value },
        { "op": "replace", "path": "/TelNumber", "value": this.newContact?.get('TelNumber').value },
        { "op": "replace", "path": "/PhoneNumber", "value": this.newContact?.get('PhoneNumber').value },
        { "op": "replace", "path": "/UnsubscribedOrderInformations", "value": this.newContact?.get('UnsubscribedOrderInformations').value },
        { "op": "replace", "path": "/UnsubscribedEmailReports", "value": this.newContact?.get('UnsubscribedEmailReports').value }
      ]
    } else {
      toPatch = [
        { "op": "replace", "path": "/Title", "value": this.newContact?.get('Title').value },
        { "op": "replace", "path": "/Name", "value": this.newContact?.get('Name').value },
        { "op": "replace", "path": "/FirstName", "value": this.newContact?.get('FirstName').value },
        { "op": "replace", "path": "/TelNumber", "value": this.newContact?.get('TelNumber').value },
        { "op": "replace", "path": "/PhoneNumber", "value": this.newContact?.get('PhoneNumber').value },
        { "op": "replace", "path": "/Function", "value": this.newContact?.get('Function').value },
        { "op": "replace", "path": "/Email", "value": this.newContact?.get('Email').value },
        { "op": "replace", "path": "/UnsubscribedOrderInformations", "value": this.newContact?.get('UnsubscribedOrderInformations').value },
        { "op": "replace", "path": "/UnsubscribedEmailReports", "value": this.newContact?.get('UnsubscribedEmailReports').value }
      ]
    }

    this.dataService?.editContact(toPatch, this.data.id)?.subscribe(data => {
      if ((data?.status == 204)) {
        this.dataService?.getProfile();
        this.data.dialog?.closeAll();

      }
      else {
        this.data.dialog?.closeAll();
      }
    })
  }
}


