import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../interfaces';
import { Observable, combineLatest, of } from 'rxjs';
import { Product } from '../core/models/product';

import * as orderSelector from '../core/store/order/order.selectors';
import * as uiSelector from '../core/store/ui/ui.selectors';
import { Step2Component } from '../step2/step2.component';
import { Step3Component } from '../step3/step3.component';
import { Step1Component } from '../step1/step1.component';
import { SetResetStepper } from '../core/store/ui';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import { DataService } from '../core/services/data.service';
import { PackDB } from '../core/models/PackDB';
import { delay, first, switchMap, take } from 'rxjs/operators';
import { Address } from '../core/models/address';
import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType } from '../core/models/user';

@Component({
  selector: 'app-order-steppers',
  templateUrl: './order-steppers.component.html',
  styleUrls: ['./order-steppers.component.scss']
})
export class OrderSteppersComponent implements OnInit {
  $products: Observable<Product[]>;
  $hasImmoAccess: Observable<boolean>;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild(Step1Component, { static: true }) Step1Component: Step1Component;
  @ViewChild(Step2Component, { static: true }) Step2Component: Step2Component;
  @ViewChild(Step3Component, { static: true }) Step3Component: Step3Component;

  invoiceTo: string;
  products: Product[];
  packs: PackDB[];
  address: Address;
  shouldHideOrderStepperAndShowNotaryRedirectView$: Observable<boolean>

  constructor(
    private store: Store<AppState>,
    translate: TranslateService,
    private dataService: DataService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    translate.setDefaultLang('fr');
    this.store.select(uiSelector.getLanguage).subscribe(ln => translate.use(ln));
  }

  ngOnInit() {
    this.store.select(orderSelector.getUserType).subscribe((consumerType) => this.dataService.getPriceFromAPI({ consumerType }))
    this.dataService.getPacksFromAPI().subscribe(packs => {
      this.packs = packs;
    });
    this.$products = this.store.select(orderSelector.getProducts);
    this.store.select(orderSelector.getInvoiceTo).subscribe(i => (this.invoiceTo = i));
    this.store.select(orderSelector.getAddress).subscribe(a => (this.address = a));

    this.$hasImmoAccess = this.store.select(orderSelector.getHasImmoAccess);

    this.store
      .select(uiSelector.getResetStepper)
      .pipe(delay(500))
      .subscribe(bool => {
        if (bool && this.stepper && this.stepper !== undefined) {
          this.stepper.reset();
          this.store.dispatch(new SetResetStepper(false));
        }
      });

      // Conditionnally open login dialog
      this.route?.queryParamMap?.subscribe(params => {
        const shouldInitiallyOpenLoginDialog = (params?.get('login') ?? null) === 'true';
        const shouldInitiallyOpenLoginDialogWithPasswordRecoveryForNewUser = (params?.get('recover-password-for-new-user')) === 'true';
        if (
          shouldInitiallyOpenLoginDialog ||
          shouldInitiallyOpenLoginDialogWithPasswordRecoveryForNewUser
        ) this.openLoginDialog({ shouldRedirectToPasswordRecoveryForNewUser: shouldInitiallyOpenLoginDialogWithPasswordRecoveryForNewUser });
      });

      // Display notary redirection view
    this.shouldHideOrderStepperAndShowNotaryRedirectView$ = combineLatest(
      this.store.select(orderSelector?.getUser),
      this.store.select(orderSelector.getHasCompletedNotaireLandingDialog)
    ).pipe(
      switchMap(([user, hasCompletedNotaireLandingDialog]) => {
        const isLoggedInNotary = user.userType === UserType.Notaire;
        return of(isLoggedInNotary && !hasCompletedNotaireLandingDialog);
      }))
  }

  get frmStep1() {
    const st = this.Step1Component ? this.Step1Component.step1FormGroup : null;
    return st;
  }

  get frmStep2() {
    return this.Step2Component ? this.Step2Component.step2FormGroup : null;
  }

  get isStep2Valid() {
    const ownerAddIsValid = !!this.address?.street && !!this.address?.number && !!this.address?.city && !!this.address?.zip;

    return this.frmStep2 && this.frmStep2.valid && ownerAddIsValid;
  }

  get isStep3Valid() {
    const formGP = this.Step3Component ? this.Step3Component.otherDocFormGroup : null;
    const otherValid = this.invoiceTo === 'toOther' && formGP && formGP.valid;
    const valid = this.invoiceTo && this.invoiceTo !== '' && this.invoiceTo !== 'toOther';

    return this.isStep2Valid && (valid || otherValid);
  }

  openLoginDialog({ shouldRedirectToPasswordRecoveryForNewUser }: { shouldRedirectToPasswordRecoveryForNewUser: boolean }) {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      panelClass: ['no-padding-dialog', 'full-screen-dialog'],
      data: {
        dialog: this.dialog,
        forgotten: shouldRedirectToPasswordRecoveryForNewUser,
        recoverPasswordWindowType: shouldRedirectToPasswordRecoveryForNewUser ? 'newUser' : 'passwordLost'
      },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      // Delete query param
      this.router.navigate([], { relativeTo: this.route, queryParams: { login: null, 'recover-password-for-new-user': null }, queryParamsHandling: 'merge', replaceUrl: true });
    });
  }
}
