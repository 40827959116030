<div class="view-container">
  <header class="view-header">
    <h5>
      {{ 'notaire-landing-dialog.header' | translate }}
    </h5>
    <ul class="services-list">
      <li *ngFor="let service of displayedServices" class="service-card">
        <img alt="" [src]="service.url" />
        <p>{{ service.translationKey | translate }}</p>
      </li>
    </ul>
  </header>
  <div class="view-content">
    <p style="margin-top: 0; font-weight: 700;">{{ 'notaire-landing-dialog.content.cta' | translate }}</p>
    <ul class="redirection-links-list">
      <li *ngFor="let link of redirectionLinks" class="redirection-link">
        <a *ngIf="!link.comingSoon" [href]="link.url">
          {{ link.translationKey | translate }}
        </a>
        <span
          *ngIf="link.comingSoon"
          [matTooltip]="'notaire-landing-dialog.content.links.coming-soon' | translate"
          matTooltipPosition="right"
          >{{ link.translationKey | translate }}</span
        >
      </li>
    </ul>

    <br />

    <app-banner>{{ 'notaire-landing-dialog.content.banner' | translate }}</app-banner>
  </div>

  <footer class="view-footer">
    <button (click)="continueToCertinergieOrder()" class="btn-link skip" style="text-align: right;">
      {{ 'notaire-landing-dialog.skip' | translate }}
      <mat-icon style="vertical-align: middle;">arrow_right_alt</mat-icon>
    </button>
  </footer>
</div>
