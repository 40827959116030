import { createSelector } from '@ngrx/store';

import { IOrder } from './order.reducer';
import { AppState } from '../../../interfaces';

import { services, immoServices, panneauxServices, publicationServices } from '../../data/services';
import { User, UserType } from '../../models/user';
import { IUI } from '../ui';
import { projectID } from '../../data/theme-config';
import { ServiceType } from '../../models/serviceType';
import * as PRODUCTS from '../../../core/data/products';
import { inRange } from '../../utils/number';

export const getOrder = (state: AppState): IOrder => state.order;
export const getUser = (state: AppState): User => state.order.user;
export const getState = (state: AppState): AppState => state;

// *** PUBLIC API's ***
export const getRegion = createSelector(
  getOrder,
  (order: IOrder) => order.regionType
);
export const getRegionInfo = createSelector(
  getOrder,
  (order: IOrder) => order.region
);
export const getEstateID = createSelector(
  getOrder,
  (order: IOrder) => order.estateType
);
export const getEstateStyle = createSelector(
  getOrder,
  (order: IOrder) => order.estateStyle
);
export const getEstateName = createSelector(
  getOrder,
  (order: IOrder) => order.estateName
);
export const getNbOfAppartments = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfAppartments
);
export const getFilteredServices = createSelector(
  getState,
  (state: AppState) => filterServices(state.order, state.order.user, state.ui)
);
export const getFilteredImmoServices = createSelector(
  getState,
  (state: AppState) => filterImmoServices(state.order, state.order.user, state.ui)
);
export const getServices = createSelector(
  getOrder,
  (order: IOrder) => order.services
);
export const getProducts = createSelector(
  getOrder,
  (order: IOrder) => order.products
);
export const getImmoPack = createSelector(
  getOrder,
  (order: IOrder) => order.immoPack
);
export const getImmoPackIds = createSelector(
  getOrder,
  (order: IOrder) => order.immoPackIds
);
export const getReductions = createSelector(
  getOrder,
  (order: IOrder) => order.reductions
);
export const getOnlinePayment = createSelector(
  getOrder,
  (order: IOrder) => order.onlinePayment
);
export const getPromoCode = createSelector(
  getOrder,
  (order: IOrder) => order.promoCode
);
export const getPromoCodeID = createSelector(
  getOrder,
  (order: IOrder) => order.promoCodeID
);
export const getPriceWithoutReduction = createSelector(
  getOrder,
  (order: IOrder) => order.price
);
export const getReduction = createSelector(
  getOrder,
  (order: IOrder) => order.reduction
);
export const getReductionWithoutComm = createSelector(
  getOrder,
  (order: IOrder) => order.reductionWithoutComm
);
export const getUrgence = createSelector(
  getOrder,
  (order: IOrder) => order.urgence
);
export const getUrgencePro = createSelector(
  getOrder,
  (order: IOrder) => order.urgencePro
);
export const hasDescUrba = createSelector(
  getOrder,
  (order: IOrder) => !!order.products.find(p => p.id === PRODUCTS.DESCRIPTIF_URBA)
);
export const hasPeb = createSelector(
  getOrder,
  (order: IOrder) => order.hasPeb
);
export const hasAudit = createSelector(
  getOrder,
  (order: IOrder) => order.hasAudit
);
export const hasPebPartiel = createSelector(
  getOrder,
  (order: IOrder) => order.hasPebPartiel
);
export const hasElec = createSelector(
  getOrder,
  (order: IOrder) => order.hasElec
);
export const hasCiterne = createSelector(
  getOrder,
  (order: IOrder) => order.hasCiterne
);
export const hasPlan = createSelector(
  getOrder,
  (order: IOrder) => order.hasPlan
);
export const hasGaz = createSelector(
  getOrder,
  (order: IOrder) => order.hasGaz
);
export const nbOfElec = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfElec
);
export const nbOfGaz = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfGaz
);
export const gazChoice = createSelector(
  getOrder,
  (order: IOrder) => order.gazChoice
);
export const getUserForm = createSelector(
  getOrder,
  (order: IOrder) => order.newUser
);
export const getOwner = createSelector(
  getOrder,
  (order: IOrder) => order.owner
);
export const getContact = createSelector(
  getOrder,
  (order: IOrder) => order.contact
);
export const getAddress = createSelector(
  getOrder,
  (order: IOrder) => order.address
);
export const getAddressZipcode = createSelector(
  getOrder,
  (order: IOrder) => order.address.zip
);
export const getPaymentType = createSelector(
  getOrder,
  (order: IOrder) => order.paymentType
);
export const getActeType = createSelector(
  getOrder,
  (order: IOrder) => order.acteType
);
export const getSendByMail = createSelector(
  getOrder,
  (order: IOrder) => order.sendByMail
);
export const getRemarks = createSelector(
  getOrder,
  (order: IOrder) => order.remarks
);
export const getInvoiceTo = createSelector(
  getOrder,
  (order: IOrder) => order.invoiceTo
);
export const getDocument = createSelector(
  getOrder,
  (order: IOrder) => order.document
);
/* export const getNewUserType = createSelector(
  getOrder,
  (order: IOrder) => order.newUserType
); */
export const getGoGeyKeys = createSelector(
  getOrder,
  (order: IOrder) => order.goGetKeys
);
export const getAddressKeyPrice = createSelector(
  getOrder,
  (order: IOrder) => order.addressKeyPrice
);
export const getCommission = createSelector(
  getOrder,
  (order: IOrder) => order.commission
);
export const getContactChoice = createSelector(
  getOrder,
  (order: IOrder) => order.contactChoice
);
export const getDateButoir = createSelector(
  getOrder,
  (order: IOrder) => order.dateButoir
);
export const getCustomPrices = createSelector(
  getUser,
  (user: User) => user.customPrices
);
export const getPricingPlan = createSelector(
  getUser,
  (user: User) => user.pricingPlan
);
export const getFilteredPanneauxServices = createSelector(
  getState,
  (state: AppState) => filterPanneauxServices(state.order, state.ui)
);
export const getFilteredPublicationsServices = createSelector(
  getState,
  (state: AppState) => filterPublicationsServices(state.order)
);

// *** PUBLIC USER API's ***
export const getUserInfo = createSelector(
  getUser,
  (user: User) => {
    const u = new User();
    u.title = user.title;
    u.firstname = user.firstname;
    u.lastname = user.lastname;
    u.phone = user.phone;
    u.gsm = user.gsm;
    u.userType = user.userType;
    u.sendByMail = user.sendByMail;
    u.email = user.email;
    u.address = user.address;
    u.userid = user.userid;
    u.companyname = user.companyname;
    u.commercialname = user.commercialname;
    u.tva = user.tva;
    return u;
  }
);
export const getUserNotifications = createSelector(
  getUser,
  (user: User) => user.notifications
);
export const getUnpaidAmount = createSelector(
  getUser,
  (user: User) => user.unpaidAmount
);
export const getUnpaidCount = createSelector(
  getUser,
  (user: User) => user.unpaidCount
);
export const getPendingOrdersCount = createSelector(
  getUser,
  (user: User) => user.pendingOrders
);
export const getUnpaidCallbackAmount = createSelector(
  getUser,
  (user: User) => user.unpaidCallbackAmount
);
export const getUnpaidCallbackCount = createSelector(
  getUser,
  (user: User) => user.unpaidCallbackCount
);
export const getYearlyOrdersCount = createSelector(
  getUser,
  (user: User) => user.yearlyOrdersCount
);
export const getOngoingOrdersCount = createSelector(
  getUser,
  (user: User) => user.ongoingOrders
);
export const getNewOrdersCount = createSelector(
  getUser,
  (user: User) => user.newOrdersCount
);
export const getLatestOrdersCount = createSelector(
  getUser,
  (user: User) => user.latestOrdersCount
);
export const getCanceledOrdersCount = createSelector(
  getUser,
  (user: User) => user.canceledOrdersCount
);
export const getSuspensOrdersCount = createSelector(
  getUser,
  (user: User) => user.suspensOrdersCount
);
export const getSingleOrderId = createSelector(
  getUser,
  (user: User) => user.orderId
);
export const getOrdersCount = createSelector(
  getUser,
  (user: User) => user.ordersCount
);
export const getUnsignedBdc = createSelector(
  getUser,
  (user: User) => user.unsignedBdc
);
export const getPendingOrders = createSelector(
  getUser,
  (user: User) => user.pendingOrders
);
export const getUserID = createSelector(
  getUser,
  (user: User) => user.userid
);
export const getHasImmoAccess = createSelector(
  getUser,
  (user: User) => user.hasImmoAccess
);
export const getIsLoggedIn = createSelector(
  getUser,
  (user: User) => user.userid !== ''
);
export const getUserType = createSelector(
  getUser,
  (user: User) => user.userType
);
export const getUserRoles = createSelector(
  getUser,
  (user: User) => user.roles
);
export const getHasCommission = createSelector(
  getUser,
  (user: User) => user.hasCommission
);
export const getUserEmail = createSelector(
  getUser,
  (user: User) => user.email
);
export const getUserZip = createSelector(
  getUser,
  (user: User) => user.address.zip
);
export const getUserName = createSelector(
  getUser,
  (user: User) => (user.commercialname ? user.commercialname : user.lastname + ' ' + user.firstname)
);
export const getNN = createSelector(
  getOrder,
  (order: IOrder) => order.nn
);
export const pebNb = createSelector(
  getOrder,
  (order: IOrder) => order.pebNb
);

export const packID = createSelector(
  getOrder,
  (order: IOrder) => order.packID
);

export const getNbOfIso = createSelector(
  getOrder,
  (order: IOrder) => order.nbOfIso
);

export const getReason = createSelector(
  getOrder,
  (order: IOrder) => order.reason
);

export const getClientType = createSelector(
  getOrder,
  (order: IOrder) => order.clientType
);

export const getVenteType = createSelector(
  getOrder,
  (order: IOrder) => order.venteType
);

export const getVenteStatut = createSelector(
  getOrder,
  (order: IOrder) => order.venteInfo.ventePub.venteStatut
);

export const getVenteInfo = createSelector(
  getOrder,
  (order: IOrder) => order.venteInfo
);

export const getParutions = createSelector(
  getOrder,
  (order: IOrder) => order.parutions
);

export const getParutionsComment = createSelector(
  getOrder,
  (order: IOrder) => order.parutionsComment
);

export const getVisits = createSelector(
  getOrder,
  (order: IOrder) => order.visits
);

export const getParutionsLength = createSelector(
  getOrder,
  (order: IOrder) => order.parutions && order.parutions.length
);

export const getVisitsLength = createSelector(
  getOrder,
  (order: IOrder) => order.visits && order.visits.length
);

export const getVisitContact = createSelector(
  getOrder,
  (order: IOrder) => order.visitContact
);

export const getVisitAdValoris = createSelector(
  getOrder,
  (order: IOrder) => order.visitAdValoris
);

export const getPackID = createSelector(
  getOrder,
  (order: IOrder) => order.immoPack
);

export const getPackName = createSelector(
  getOrder,
  (order: IOrder) => order.packName
);

export const getImmoOrCtrl = createSelector(
  getOrder,
  (order: IOrder) => order.immoOrCtrl
);

export const getSplitInvoice = createSelector(
  getOrder,
  (order: IOrder) => order.splitInvoice
);

export const getProductsForAgency = createSelector(
  getOrder,
  (order: IOrder) => order.productsForAgency
);

export const getAskForExpertise = createSelector(
  getOrder,
  (order: IOrder) => order.askForExpertise
);

export const getAskForOffer = createSelector(
  getOrder,
  (order: IOrder) => order.askForOffer
);

export const getToken = createSelector(
  getOrder,
  (order: IOrder) => order.token
);

export const getConsumerAddresses = createSelector(
  getUser,
  (user: User) => user.consumerAddresses
);

export const getAddressKey = createSelector(
  getOrder,
  (order: IOrder) => order.addressKeyID
);

export const getPanels = createSelector(
  getOrder,
  (order: IOrder) => order.panels
);

export const getPanelsInfo = createSelector(
  getOrder,
  (order: IOrder) => order.panelsInfo
);

export const getIsThirdPartyNotaria = createSelector(
  getUser,
  (user: User) =>
    user.userid === '29c0bd36-3c99-4d5d-b8d2-a2e8e63b3ca8' ||
    user.userid === 'f1b27b67-0242-4343-91b7-01a076044b72' ||
    user.userid === '1733a3e5-fdd8-4f7a-a294-c13b92ce37aa' ||
    user.userid === '9c72e6a2-2fbc-4dc3-9c00-08b25c683b6c'
);

export const getThirdParyNotariaName = createSelector(
  getUser,
  (user: User) =>
    user.userid === '29c0bd36-3c99-4d5d-b8d2-a2e8e63b3ca8'
      ? 'NVN'
      : user.userid === 'f1b27b67-0242-4343-91b7-01a076044b72'
      ? 'Maison du notariat du Hainaut'
      : user.userid === '1733a3e5-fdd8-4f7a-a294-c13b92ce37aa'
      ? 'Maison du notariat de Namur'
      : user.userid === '9c72e6a2-2fbc-4dc3-9c00-08b25c683b6c'
      ? 'Maison du notariat de Liege'
      : ''
);

export const getHasCompletedNotaireLandingDialog = createSelector(
  getOrder,
  (order: IOrder) => order.hasCompletedNotaireLandingDialog
);

const SERVICE_RESTRICTIONS_BY_ZIPCODE_MANIFEST: Record<string, { isUnavailable: (zipcode: number) => boolean }> = {
  [PRODUCTS.AUDIT]: {
    isUnavailable: zipcode => {
      return (
        inRange(zipcode, 5537, 5576) ||
        inRange(zipcode, 5600, 5680) ||
        inRange(zipcode, 6440, 6470) ||
        inRange(zipcode, 6590, 6596) ||
        inRange(zipcode, 6600, 6890) ||
        inRange(zipcode, 6920, 6997) ||
        inRange(zipcode, 7300, 7784) ||
        inRange(zipcode, 7880, 7973)
      );
    }
  }
};

function filterServices(order: IOrder, user: User, ui: IUI) {
  let serv: ServiceType[] = services;

  if (order.address.zip) {
    // Apply restrictions by zip code
    serv = services.filter(({ id: productId }) => {
      return Object.keys(SERVICE_RESTRICTIONS_BY_ZIPCODE_MANIFEST).every(productIdConcernedByRestriction => {
        const { isUnavailable } = SERVICE_RESTRICTIONS_BY_ZIPCODE_MANIFEST[productIdConcernedByRestriction];

        if (productId != +productIdConcernedByRestriction) return true; // Product is not concerned by this restriction
        return !isUnavailable(+order.address.zip);
      });
    });
  }

  if (user.userType === UserType.Installateur) {
    serv = serv.filter(
      s =>
        s.id === PRODUCTS.ELEC ||
        s.id === PRODUCTS.ELEC_SUPP ||
        s.id === PRODUCTS.ELEC_NONDOM_MAX125A ||
        s.id === PRODUCTS.ELEC_NONDOM_OVER125A ||
        s.id === PRODUCTS.GAZ_CONTROLE ||
        s.id === PRODUCTS.GAZ_SUPP ||
        s.id === PRODUCTS.GAZ_CONTROLE_PERIO ||
        s.id === PRODUCTS.ELEC_PHOTOVOLTA
    );
  } else {
    if (order.estateType === -1) {
      return [];
    }
    if (order.regionType > -1) {
      serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
    }
    if (order.estateStyle !== -1 && order.estateStyle !== 4) {
      serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
    }
    if (order.estateStyle === 4) {
      serv = serv.filter(service => service.biens.indexOf(order.estateType) > -1);
    }
    if (!ui.showMoreServices && order.reason > -1 && order.estateStyle !== 4) {
      serv = serv.filter(service => service.reasons && service.reasons.indexOf(order.reason) > -1);
    }
    /* if (order.venteType > -1) {
      serv = serv.filter(service => service.venteTypes && service.venteTypes.indexOf(order.venteType) > -1);
    } */
  }

  if (
    (projectID === 'namur' || projectID === 'hainaut' || projectID === 'liege' || projectID === 'nvn') &&
    order.immoPack !== -1
  ) {
    const selectedPackIds = order.immoPack !== -1 ? order.immoPackIds : [];
    serv = serv.filter(
      service => !selectedPackIds.some(id => id === service.id) || !order.products.some(pr => pr.id === service.id)
    );
  }
  if (!ui.showMoreServices && order.immoOrCtrl === 0) {
    serv = serv.filter(
      service =>
        (service.id > 0 && service.id < 10) ||
        service.id === PRODUCTS.ELEC_NONDOM_MAX125A ||
        service.id === PRODUCTS.ELEC_NONDOM_OVER125A
    );
  }

  return serv;
}

function filterImmoServices(order: IOrder, user: User, ui: IUI) {
  let serv: ServiceType[] = user.userType === UserType.Installateur ? [] : immoServices;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1 && order.estateStyle !== 4) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  }
  if (order.estateStyle === 4) {
    serv = serv.filter(service => service.biens.indexOf(order.estateType) > -1);
  }
  if (!order.user.hasImmoAccess && order.user.userType) {
    serv = serv.filter(
      s => !s.userTypes || s.userTypes.length === 0 || s.userTypes.some(ut => ut === order.user.userType)
    );
  }
  if (!ui.showMoreServices && order.reason > -1 && order.estateStyle !== 4) {
    serv = serv.filter(service => service.reasons && service.reasons.indexOf(order.reason) > -1);
  }
  if (order.venteType > -1) {
    serv = serv.filter(service => service.venteTypes && service.venteTypes.indexOf(order.venteType) > -1);
  }
  if (
    (projectID === 'namur' || projectID === 'hainaut' || projectID === 'liege' || projectID === 'nvn') &&
    order.immoPack !== -1
  ) {
    const selectedPackIds = order.immoPack !== -1 ? order.immoPackIds : [];
    serv = serv.filter(
      service => !selectedPackIds.some(id => id === service.id) || !order.products.some(pr => pr.id === service.id)
    );
  }
  if (!ui.showMoreServices) {
    // serv = [];
  }

  return serv;
}

function filterPanneauxServices(order: IOrder, ui: IUI) {
  let serv = panneauxServices;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1 && order.estateStyle < 4) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  } else if (order.estateStyle !== -1) {
    serv = serv.filter(service => service.biens.indexOf(order.estateType) > -1);
  }
  if (
    !(projectID === 'namur' || projectID === 'hainaut' || projectID === 'nvn' || projectID === 'liege') &&
    !ui.showMoreServices
  ) {
    serv = [];
  }

  return serv;
}

function filterPublicationsServices(order: IOrder) {
  let serv = publicationServices;
  if (order.regionType > -1) {
    serv = serv.filter(service => service.regions.indexOf(order.regionType) > -1);
  }
  if (order.estateStyle !== -1) {
    serv = serv.filter(service => service.biens.indexOf(order.estateStyle) > -1);
  }

  return serv;
}
