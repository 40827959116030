import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notif-order-icon',
  template: `
    <ng-container *ngIf="!hide; else alternateIcon">
      <svg
        [class]="svgClass"
        [ngClass]="customClass"
        [attr.width]="width"
        [attr.height]="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0002 14.8999C17.1528 16.3473 15.9476 17.5525 14.5002 18.3999C13.0438 19.2512 11.3872 19.6999 9.70022 19.6999C8.01326 19.6999 6.35664 19.2512 4.90022 18.3999C3.45285 17.5525 2.24762 16.3473 1.40022 14.8999C0.530757 13.4512 0.0806989 11.7894 0.100218 10.0999C0.0806989 8.41042 0.530757 6.74867 1.40022 5.29993C2.24762 3.85256 3.45285 2.64732 4.90022 1.79993C6.35664 0.948642 8.01326 0.5 9.70022 0.5C11.3872 0.5 13.0438 0.948642 14.5002 1.79993C15.9476 2.64732 17.1528 3.85256 18.0002 5.29993C18.8697 6.74867 19.3197 8.41042 19.3002 10.0999C19.3197 11.7894 18.8697 13.4512 18.0002 14.8999ZM15.7002 7.99993C15.7623 7.95335 15.8127 7.89296 15.8474 7.82353C15.8821 7.75411 15.9002 7.67755 15.9002 7.59993C15.9002 7.52231 15.8821 7.44575 15.8474 7.37632C15.8127 7.30689 15.7623 7.2465 15.7002 7.19993L14.9002 6.29993C14.8536 6.23783 14.7933 6.18743 14.7238 6.15271C14.6544 6.118 14.5778 6.09993 14.5002 6.09993C14.4226 6.09993 14.346 6.118 14.2766 6.15271C14.2072 6.18743 14.1468 6.23783 14.1002 6.29993L8.30022 12.0999L5.60022 9.39993C5.55364 9.33783 5.49325 9.28743 5.42383 9.25271C5.3544 9.218 5.27784 9.19993 5.20022 9.19993C5.1226 9.19993 5.04604 9.218 4.97661 9.25271C4.90719 9.28743 4.84679 9.33783 4.80022 9.39993L4.00022 10.2999C3.93812 10.3465 3.88772 10.4069 3.85301 10.4763C3.81829 10.5457 3.80022 10.6223 3.80022 10.6999C3.80022 10.7775 3.81829 10.8541 3.85301 10.9235C3.88772 10.993 3.93812 11.0534 4.00022 11.0999L8.00022 15.0999C8.04679 15.162 8.10718 15.2124 8.17661 15.2471C8.24604 15.2819 8.3226 15.2999 8.40022 15.2999C8.47784 15.2999 8.5544 15.2819 8.62383 15.2471C8.69326 15.2124 8.75365 15.162 8.80022 15.0999L15.7002 7.99993Z"
          fill="#8EC640"
        />
      </svg>
    </ng-container>
    <ng-template #alternateIcon>
      <svg class="col-3" width="20" height="20" viewBox="0 0 20 20"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.0002 14.8999C17.1528 16.3473 15.9476 17.5525 14.5002 18.3999C13.0438 19.2512 11.3872 19.6999 9.70022 19.6999C8.01326 19.6999 6.35664 19.2512 4.90022 18.3999C3.45285 17.5525 2.24762 16.3473 1.40022 14.8999C0.530757 13.4512 0.0806989 11.7894 0.100218 10.0999C0.0806989 8.41042 0.530757 6.74867 1.40022 5.29993C2.24762 3.85256 3.45285 2.64732 4.90022 1.79993C6.35664 0.948642 8.01326 0.5 9.70022 0.5C11.3872 0.5 13.0438 0.948642 14.5002 1.79993C15.9476 2.64732 17.1528 3.85256 18.0002 5.29993C18.8697 6.74867 19.3197 8.41042 19.3002 10.0999C19.3197 11.7894 18.8697 13.4512 18.0002 14.8999Z"
          fill="#FF5722" />
        <rect x="13.0601" y="5.57471" width="1.88931" height="11.3359" rx="0.472328"
          transform="rotate(45 13.0601 5.57471)" fill="white" />
        <rect x="14.396" y="13.5903" width="1.88931" height="11.3359" rx="0.472328"
          transform="rotate(135 14.396 13.5903)" fill="white" />
      </svg>
    </ng-template>
  `,
  styleUrls: [],
})
export class NotifOrderIconComponent {
  @Input() width = '20';
  @Input() height = '20';
  @Input() svgClass = 'col-3';
  @Input() customClass: string | string[] = '';
  @Input() hide = false; // This will control the visibility
}