<div id="dialog-container" *ngIf="!this.sent">
  <header id="dialog-header">
    <h5>
      <fa-icon [icon]="['fas', 'file-invoice']"></fa-icon>
      {{ 'specific-request-dialog.dialog-title' | translate }}
    </h5>
  </header>
  <div id="dialog-intro">
    <p>
      {{ 'specific-request-dialog.dialog-content' | translate }} <a href="tel:028802171">02 880 21 71</a>
      {{ 'specific-request-dialog.dialog-content-end' | translate }}
    </p>
    <p>{{ 'specific-request-dialog.dialog-content-conclusion' | translate }}</p>
  </div>
  <form id="dialog-content" (ngSubmit)="onSubmit()" [formGroup]="devisFormGroup">
    <mat-form-field class="devis-firstname" appearance="outline">
      <mat-label>{{ 'section-user.disco.firstname' | translate }}</mat-label>
      <input
        matInput
        placeholder="Jane"
        name="firstname"
        autocomplete="given-name"
        formControlName="firstName"
        required
      />
      <mat-error *ngIf="devisFormGroup?.get('firstName')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-lastname" appearance="outline">
      <mat-label>{{ 'section-user.disco.lastname' | translate }}</mat-label>
      <input
        matInput
        placeholder="Doe"
        name="lastname"
        autocomplete="family-name "
        formControlName="lastName"
        required
      />
      <mat-error *ngIf="devisFormGroup?.get('lastName')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-phone" appearance="outline">
      <mat-label>{{ 'section-user.disco.phone' | translate }}</mat-label>
      <input
        matInput
        placeholder="0123 45 67 89"
        type="tel"
        name="phone"
        autocomplete="tel"
        formControlName="phone"
        required
      />
      <mat-error *ngIf="devisFormGroup?.get('phone')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-mail" appearance="outline">
      <mat-label>{{ 'section-user.disco.email' | translate }}</mat-label>
      <input
        matInput
        placeholder="youremail@example.com"
        type="email"
        name="email"
        autocomplete="email"
        formControlName="email"
        required
      />
      <mat-error *ngIf="devisFormGroup?.get('email')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <!-- <mat-form-field class="devis-street" name="street" appearance="outline">
      <mat-label>{{ 'section-user.disco.street' | translate }}</mat-label>
      <input matInput placeholder="Rue de l'exemple" formControlName="userStreet" required />
      <mat-error *ngIf="devisFormGroup?.get('userStreet')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-num" appearance="outline">
      <mat-label>{{ 'section-user.disco.num' | translate }}</mat-label>
      <input matInput placeholder="1" autocomplete="off" formControlName="userNum" required />
      <mat-error *ngIf="devisFormGroup?.get('userNum')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-postalcode" appearance="outline">
      <mat-label>{{ 'section-user.disco.zip' | translate }}</mat-label>
      <input matInput placeholder="1234" formControlName="userZip" required />
      <mat-error *ngIf="devisFormGroup?.get('userZip')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="devis-city" appearance="outline">
      <mat-label>{{ 'section-user.disco.city' | translate }}</mat-label>
      <input matInput placeholder="Exemple ville" formControlName="userCity" required />
      <mat-error *ngIf="devisFormGroup?.get('userCity')?.hasError('required')">
        {{ 'error-messages.required-blank' | translate }}
      </mat-error>
    </mat-form-field> -->
    <mat-form-field appearance="outline" class="text-area">
      <textarea
        matInput
        formControlName="remarks"
        cdkTextareaAutosize
        cdkAutosizeMinRows="2"
        cdkAutosizeMaxRows="5"
        placeholder="{{ 'step4.section-comment.placeholder' | translate }}"
      ></textarea>
    </mat-form-field>
  </form>
  <footer id="dialog-footer">
    <button (click)="close()" class="btn">{{ 'common.cancel' | translate }}</button>
    <button type="submit" (click)="onSubmit()" class="btn-primary">
      {{ 'specific-request-dialog.send-request-btn' | translate }}
    </button>
  </footer>
</div>
<div id="dialog-confirm" *ngIf="this.sent">
  <picture>
    <fa-icon [icon]="['fas', 'check']"></fa-icon>
  </picture>
  <h6>{{ 'specific-request-dialog.success-title' | translate }}</h6>
  <p>
    {{ 'specific-request-dialog.success-content' | translate }}
  </p>
  <button (click)="close()" class="btn">{{ 'specific-request-dialog.success-action' | translate }}</button>
</div>
