import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintIconComponent } from './print-icon/print-icon.component';
import { FilterIconsComponent } from './filter-icons/filter-icons.component';
import { InfoIconComponent } from './info-icon/info-icon-component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { NotifOrderIconComponent } from './notif-order-icon/notif-order-icon.component';
import { NotifAllIconComponent } from './notif-all-icon/notif-all-icon.component';
import { EditIconComponent } from './edit-icon/edit-icon.component';

@NgModule({
  declarations: [PrintIconComponent, FilterIconsComponent,
    InfoIconComponent, CloseIconComponent, NotifOrderIconComponent, 
    NotifAllIconComponent, EditIconComponent], // Declare the components
  imports: [CommonModule],
  exports: [PrintIconComponent, FilterIconsComponent,
    InfoIconComponent, CloseIconComponent, NotifOrderIconComponent, 
    NotifAllIconComponent, EditIconComponent] // Export for use in other modules
})
export class IconsModule { }