import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import 'flatpickr/dist/flatpickr.css';

import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'; // Import library explicitly
import {
  faAngleDown,
  faCheckCircle,
  faUser,
  faTrash,
  faUpload,
  // Add other icons used in the project
} from '@fortawesome/free-solid-svg-icons';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

import { HtvaPipe } from './pipes/htva.pipe';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { SpinnerOverlayComponent } from '../core/spinner-overlay/spinner-overlay.component';
import { SortbyPipe } from './pipes/sortby.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { InlineSVGModule } from 'ng-inline-svg';
import { FlatpickrModule } from 'angularx-flatpickr';
import { RatingModule } from 'ng-starrating';

import * as fpickr from 'flatpickr/dist/l10n/fr';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { TimeOfDayPipe } from './pipes/time-of-day.pipe';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { BigSelectComponent } from '../step1/big-select/big-select.component';
import { RouterModule } from '@angular/router';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatBadgeModule,
    ReactiveFormsModule,
    FormsModule,
    FileUploadModule,
    RatingModule,
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FlatpickrModule.forRoot({
      locale: fpickr.default.fr,
      defaultHour: 12
    })
  ],
  exports: [
    CommonModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatOptionModule,
    MatSliderModule,
    MatDialogModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatIconModule,
    MatAutocompleteModule,
    HtvaPipe,
    SortbyPipe,
    SafeHtmlPipe,
    TimeOfDayPipe,
    HeaderComponent,
    TooltipComponent,
    FileUploadModule,
    FooterComponent,
    SpinnerOverlayComponent,
    RatingModule,
    InlineSVGModule,
    TranslateModule,
    FlatpickrModule,
    ShareButtonsModule,
    ShareIconsModule,
    BigSelectComponent,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 8000 } },
    CurrencyPipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add schema for custom elements
  declarations: [
    SpinnerOverlayComponent,
    HeaderComponent,
    FooterComponent,
    HtvaPipe,
    SortbyPipe,
    SafeHtmlPipe,
    TooltipComponent,
    TimeOfDayPipe,
    BigSelectComponent
  ]
})
export class SharedModule {
  constructor() {
    // Add necessary icons to the library
    library.add(faAngleDown, faCheckCircle, faUser, faTrash, faUpload);
  }
}
